const intructors = [
    {
        id: 1,
        classnames: 'team-member border-ra4  border-f-e6f3ff',
        src: 'images/instructors/instructor-index-1.jpg',
        name: 'Aleksejs Petenko',
        position: 'Teacher',
        description: 'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        classicon: 'socails flat-text-center no-bg border-t-e6f3ff'
    },
    {
        id: 2,
        classnames: 'team-member border-ra4  border-f-e6f3ff',
        src: 'images/instructors/instructor-index-2.jpg',
        name: 'Anna Kunytska',
        position: 'Teacher',
        description: 'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        classicon: 'socails flat-text-center no-bg border-t-e6f3ff'
    },
    {
        id: 3,
        classnames: 'team-member border-ra4  border-f-e6f3ff',
        src: 'images/instructors/instructor-index-3.jpg',
        name: 'Ben Daniel',
        position: 'Teacher',
        description: 'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        classicon: 'socails flat-text-center no-bg border-t-e6f3ff'
    }

]

export default intructors;