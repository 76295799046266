import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Header, TopBar , Paramater, Footer, BottomBar, Loader} from '../layouts/general';
import {AccordionCompent} from '../layouts/pages';

class Faqs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'FAQ'
                }
            ],
        }
    }
    render() {
        return (
            <div className="bg-body2">
                <div className="boxed">
                <Loader />
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }
                    <section className="flat-title-page parallax parallax4 style2">
                        <div className="overlay" /> 
                        <div className="container wrap-title-page flat-text-center">
                            <div className="title-page">
                                <h2 className="title font-weight-700"><Link to="#">Frequently Asked Questions</Link></h2>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/faq">FAQs</Link></li>
                                </ul>
                            </div>
                            {/* <div className="flat-search">
                                <div className="form">
                                <input type="search" name="key" placeholder="Search" required />
                                <button className="btn-search flat-button"><span className="icon-search" /></button>
                                </div>
                            </div> */}
                        </div>
                    </section>
                    <div className="content-wrap faqs-page">
                        <div className="container">
                            <div className="content-page-wrap">
                                <div className="row">
                                    <div className="col-md-1" />
                                    <div className="col-md-10">
                                        <div className="flat-faqs">
                                            <div className="heading flat-text-center">
                                                <h6 className="title-small">QUESTIONS AND ANSWERS (Q&amp;A)</h6>
                                                <h3 className="title font-Poppins">Frequently Asked Questions (FAQ)</h3>
                                            </div>
                                            <AccordionCompent />
                                        </div>     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="flat-feature parallax parallax2">
                        <div className="container">
                            <Paramater />
                        </div>
                    </section>
                    <Footer />
                    <BottomBar />
                </div>
            </div>
        );
    }
}

export default Faqs;