import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import datablog from './dataBlog'
class ListBlogComponent extends Component {
    render() {
        return (
            <div className="col-lg-8 col-md-12">
                <div className="content-page-wrap">
                    <div className="flat-post">
                        {
                            datablog.map(data => (
                                <div className="post transition-vline border-ra4" key={data.id}>
                                    <div className="post-img img-vline">
                                        <Link to="#"><img src={data.srcimg} alt="nstructive" /></Link>
                                        <div className="overlay">
                                            <span className="vline" />
                                            <span className="vline vline-bottom" />
                                        </div>
                                    </div>
                                    <div className="post-content">
                                        <h3 className="title"><Link to="#">{data.title}</Link></h3>
                                        <div className="list-meta">
                                            <ul className="list">
                                                <li className="time">{data.time}</li>
                                                <li className="counter-like">{data.like}</li>
                                                <li className="counter-comment">{data.comments}</li>
                                                <li className="folder">{data.folder}</li> 
                                            </ul>
                                        </div>
                                        <p className="text">{data.description}</p>
                                        <div className="wrap-btn">
                                            <Link to="#" className="flat-button btn-read-more border-ra4">READ MORE</Link>
                                        </div>
                                    </div>   
                                </div>
                            ))
                        }
                    </div>
                    <div className="flat-paginations">
                        <ul className="list pagination-wrap">
                            <li className="disabled"><Link to="#" className="btn-navs">PRE</Link></li>
                            <li className="active"><Link to="#">1</Link></li>
                            <li><Link to="#">2</Link></li>
                            <li><Link to="#">3</Link></li>
                            <li><Link to="#">4</Link></li>
                            <li><Link to="#">5</Link></li>
                            <li><Link to="#" className="btn-navs">NEXT</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default ListBlogComponent;