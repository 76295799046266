import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class InforComponent extends Component {
    render() {
        return (
            <div className="container">
                <div className="content-page-wrap">
                    <div className="flat-about-info">
                        <div className="info-img border-ra4">
                            <Link to="#"><img width="635" height="625" src="images/about/into.jpg " alt="nstructive" /></Link>
                        </div>
                        <div className="info-content border-ra4 border-f-e6f3ff">
                            <div className="heading">
                                <h6 className="title-small"> NEED TO KNOW </h6>
                                <h3 className="title">SPARKING ADVANCEMENT</h3>
                            </div>
                            <p className="text">Our company was founded with a mission to 
                             transform education by leveraging technology and expertise to empower learners and educators across the world.</p>
                            <p className="text">We specialize in providing a wide range of education services, including e-learning solutions, content development, assessment and evaluation services, and consultancy services. </p>
                            <p className="text font-Publico">We are proud of our global footprint, with a presence in key markets across the globe. Our commitment to excellence has earned us
                            a reputation as a trusted partner to educational institutions and corporations alike.</p>
                            {/* <p className="text">With the dawn of the twentieth century, the Company handed over the Institute to the
                                University of London. It was re-christened Goldsmiths College and the modern era of Goldsmiths had
                                begun.</p> */}
                            <div className="wrap-btn">
                                <Link to="/contact-us" onClick={() => { window.location.href = "/contact-us" }} className="flat-button btn-contact ">CONTACT US</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InforComponent;