import React, { Component } from 'react';
import {Link} from 'react-router-dom'
class Featured extends Component {
    render() {
        return (
            <section className="flat-featured style3">
                <div className="wrap-featured">
                    <div className="container">
                        <div className="flat-iconbox clearfix style3 ">
                            <div className="iconbox one-of-three style3 flat-text-center left  ">
                                <div className="iconbox-icon">
                                    <span className="icon-contract-1"><span className="path1" /><span className="path2" /><span
                                            className="path3" /><span className="path4" /><span className="path5" /><span
                                            className="path6" /><span className="path7" /><span className="path8" /><span
                                            className="path9" /><span className="path10" /><span className="path11" /><span
                                            className="path12" /><span className="path13" /><span className="path14" /></span>
                                </div>
                                <div className="iconbox-content">
                                    <h6 className="title"><Link to="/courses-grid-v1" onClick={() => {window.location.href="/courses-grid-v1"}}>Course Management</Link></h6>
                                </div>
                            </div>
                            <div className="iconbox one-of-three style3 flat-text-center active">
                                <div className="iconbox-icon">
                                    <span className="icon-portfolio-1"><span className="path1" /><span className="path2" /><span
                                            className="path3" /><span className="path4" /><span className="path5" /><span
                                            className="path6" /><span className="path7" /><span className="path8" /><span
                                            className="path9" /><span className="path10" /><span className="path11" /><span
                                            className="path12" /><span className="path13" /><span className="path14" /><span
                                            className="path15" /><span className="path16" /><span className="path17" /></span>
                                </div>
                                <div className="iconbox-content">
                                    <h6 className="title"><Link to="/courses-grid-v2" onClick={() => {window.location.href="/courses-grid-v2"}}>Online Learn Courses</Link></h6>
                                </div>
                            </div>
                            <div className="iconbox one-of-three style3 flat-text-center right ">
                                <div className="iconbox-icon">
                                    <span className="icon-portfolio-3"><span className="path1" /><span className="path2" /><span
                                            className="path3" /><span className="path4" /><span className="path5" /><span
                                            className="path6" /><span className="path7" /><span className="path8" /><span
                                            className="path9" /><span className="path10" /><span className="path11" /><span
                                            className="path12" /><span className="path13" /><span className="path14" /><span
                                            className="path15" /><span className="path16" /><span className="path17" /></span>
                                </div>
                                <div className="iconbox-content style3">
                                    <h6 className="title"><Link to="/instructors" onClick={() => {window.location.href="/instructors"}}>Teacher Management</Link></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Featured;