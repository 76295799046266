import React, { Component } from 'react';

class Paramater extends Component {
    render() {
        return (
            <div className="row flat-iconbox style1">
                <div className="col-lg-4 col-md-4">
                    <div className="iconbox style1 left">
                        <div className="iconbox-icon">
                            <span className="icon-book" />
                        </div>
                        <div className="iconbox-content">
                            <h5 className="title">ENGAGING CONTENT</h5>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="iconbox style1 center">
                        <div className="iconbox-icon">
                            <span className="icon-people" />
                        </div>
                        <div className="iconbox-content">
                            <h5 className="title">EXPERT INSTRUCTION</h5>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4">
                    <div className="iconbox style1 right">
                        <div className="iconbox-icon">
                            <span className="icon-key" />
                        </div>
                        <div className="iconbox-content">
                            <h5 className="title">ON-TIME DELIVERY</h5>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Paramater;