import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Paramater, SlideTestimonial, Footer, BottomBar, Loader} from '../layouts/general';
import { Header, Slider, TopBar, TitleHeading4, CourseSlide, CategoryComponent, BannerProfessional } from '../layouts/home04';

class Home04 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Home'
                }
            ],
            titleCourses: [
                {
                    id: 1,
                    title01: 'Discover',
                    title02: 'Our Popular Courses',
                    classTitle: 'title',
                    classspan: 'color-ec5252',
                    classDescription: 'description',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                }
            ],
            titleCategory: [
                {
                    id: 1,
                    title01: 'Top',
                    title02: 'Categories',
                    classTitle: 'title color-fff',
                    classspan: 'color-ec5252',
                    classDescription: 'description color-fff',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                }
            ],
            titleTestimonial: [
                {
                    id: 1,
                    title01: 'What our',
                    title02: 'students have to say',
                    classTitle: 'title',
                    classspan: 'color-ec5252',
                    classDescription: 'description',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                }
            ],
        }
    }
    
    render() {
        return (
            <div className="bg-body2">
                <div className="boxed">
                    <Loader />
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }
                    <Slider />
                    <section className="flat-feature style4">
                        <div className="overlay" />
                        <div className="container">
                            <Paramater />
                        </div>
                    </section>
                    <section className="flat-popular-courses style4">
                        <div className="container">
                            {
                                this.state.titleCourses.map(data =>(
                                    <TitleHeading4 data={data} key={data.id}/>
                                ))
                            }                    
                        </div>
                        <CourseSlide />   
                        <div className="wrap-btn flat-text-center">
                            <Link to="/courses-grid-v1" className="flat-button btn-all-sourses font-Poppins font-weight-700 border-ra4 v2">BROWSE ALL COURSES</Link>
                        </div>
                    </section>
                    <section className="flat-categories">
                        <div className="container">
                            {
                                this.state.titleCategory.map(data =>(
                                    <TitleHeading4 data={data} key={data.id}/>
                                ))
                            }  
                            <CategoryComponent />
                        </div>
                    </section>
                    <section className="flat-testimonials style4">
                        <div className="container">
                            {
                                this.state.titleTestimonial.map(data =>(
                                    <TitleHeading4 data={data} key={data.id}/>
                                ))
                            }
                            <SlideTestimonial />
                        </div>
                    </section>
                    <section className="flat-free-month parallax parallax14">
                        <div className="container">
                            <BannerProfessional />
                        </div>
                    </section>
                    <Footer />
                    <BottomBar />
                </div>
            </div>
        );
    }
}

export default Home04;