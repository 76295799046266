import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Header, TopBar, Paramater, Footer, BottomBar, Loader } from "../layouts/general";

class Terms extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: "Pages"
                }
            ],
        }
    }
    render() {
        return (
            <div className="bg-body2">
                <div className="boxed">
                    <Loader />
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id} />
                        ))
                    }
                    <section className="flat-title-page parallax parallax4 style2">
                        <div className="overlay" />
                        <div className="container wrap-title-page flat-text-center">
                            <div className="title-page">
                                <h2 className="title font-weight-700"><Link to="#">Terms and Conditions</Link></h2>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/terms-and-conditions">Terms And Conditions</Link></li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <div className="content-wrap terms-page">
                        <div className="container">
                            <div className="content-page-wrap">
                                <div className="row">
                                    <div className="col">
                                        <div className="flat-terms flat-text-justify">
                                                <div>&nbsp;</div>
                                                <div>&nbsp;</div>
                                                <div>&nbsp;</div>
                                                <p className="content">
                                                    Please read the terms and conditions listed below before using our website to become familiar with the guidelines, limitations, and rules that apply to its usage as well as that of our products and services. Please contact us at admin@nstructive.co.in. if you have any questions about our Terms of Use.
                                                </p>
                                                <p className="content">You cannot use or use our services in any way if you do not agree to any of the terms and conditions listed below. By using our website, you are deemed to have accepted all of the terms and conditions.</p>
                                                <div>&nbsp;</div>
                                                <div>
                                                    <h6 className="heading">Modifications or Changes</h6>
                                                    <div>&nbsp;</div>
                                                    <div className="content">The terms of use are subject to change or updating whenever necessary and in Nstructive exclusive discretion. You should regularly check the website for such updates to make sure you are comfortable with the changes. You can choose to reject these terms and conditions and stop using our website, products, or services if you don"t agree with them.</div>
                                                    <div className="content">No other adjustments or changes to these Terms—aside from those made by us as described here—will be valid unless they are agreed to in writing and signed by both Acadecraft and the customer.</div>
                                                </div>
                                                <div>&nbsp;</div>
                                                <div>
                                                    <h6 className="heading">Security</h6>
                                                    <div>&nbsp;</div>
                                                    <div className="content">It is necessary that you give us accurate information when submitting your personal data to us. Any impersonation attempt will result in legal action being taken against you. You guarantee that you are of legal age to enter into a deal with Acadecraft. If you accept these terms and conditions on behalf of someone else, you are acknowledging that you have the right to accept these terms and conditions on behalf of any organisation. Only those who are 13 years of age or older are allowed to access the services.</div>
                                                </div>
                                                <div>&nbsp;</div>
                                                <div>
                                                    <h6 className="heading">Permitted Use</h6>
                                                    <div>&nbsp;</div>
                                                    <div className="content">You are free to use our website and services for personal use or on behalf of the group you support. The website"s services and content cannot be sold or otherwise used for promotional purposes.</div>
                                                </div>
                                                <div>&nbsp;</div>
                                                <div>
                                                    <h6 className="heading">Payment and Refunds</h6>
                                                    <div>&nbsp;</div>
                                                    <div className="content">If the services you obtained fall short of your expectations, we shall start the refund process as long as your request is submitted within the MOU"s specified time frame. The refund will not be handled if the refund request is submitted after the agreed guarantee time has passed. Additionally, we reserve the right to extend the 30-day refund window in situations where account fraud is suspected or actualized.</div>
                                                    <div className="content">We reserve the right to refuse the refund, restrict the customer from receiving other future refunds, ban the account, and/or prevent the account from being used in the future if it is determined that the refund policy is being misused, such as if the customer has used a significant portion of the service for which the refund is requested or if the refund was already fully or partially processed in the past for the same service.</div>
                                                    <div className="content">If an account is deactivated or suspended owing to a breach of these Terms of Use, customers will not be entitled to a refund.</div>
                                                </div>
                                                <div>&nbsp;</div>
                                                <div>
                                                    <h6 className="heading">Cancellation Policy -</h6>
                                                    <div>&nbsp;</div>
                                                    <div className="content">If the cancellation is submitted within 24 hours after making the order, it will be honoured. To submit a request, kindly use your registered phone number and email address, or give us a call at +91-789 571 0403. The cancellation procedure cannot be started beyond 24 hours.</div>
                                                </div>
                                                <div>&nbsp;</div>
                                                <div>
                                                    <h6 className="heading">General Disclaimer</h6>
                                                    <div>&nbsp;</div>
                                                    <div className="content">Your use of this website constitutes acceptance of the terms and conditions listed above.</div>
                                                </div>
                                                <div>&nbsp;</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="flat-feature parallax parallax2">
                        <div className="container">
                            <Paramater />
                        </div>
                    </section>
                    <Footer />
                    <BottomBar />
                </div>
            </div>
        );
    }
}

export default Terms;