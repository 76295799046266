import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class QaService extends Component {
    render() {
        return (
            <div className="content-wrap courses-single-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content-page-wrap">
                                <div className="flat-tabs bg-fff border-ra4 border-f-e6f3ff">
                                    <ul className="tab-title style1 clearfix border-bt-e6f3ff">
                                        <li className="item-title  overview">
                                            <span className="inner">OVERVIEW</span>
                                        </li>
                                        <li className="item-title event">
                                            <span className="inner">DESCRIPTION</span>
                                        </li>
                                        <li className="item-title requirements">
                                            <span className="inner">DOWNLOAD SAMPLES</span>
                                        </li>
                                    </ul>
                                    <div className="tab-content-wrap">
                                        <div className="tab-content">
                                            <div className="item-content">
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="text-wrap img-right clearfix">
                                                            <h6 className="title">Key Issue</h6>
                                                            <p className="text">Q&A is used in schools, colleges, and other educational institutions all around the world to improve learner comprehension and evaluation procedures. They are an element of traditional learning, but they have altered as technology has advanced. As a result, clients nowadays desire dynamic Q&A that appeals to current learners. Unfortunately, locating such eLearning services that provide ideal solutions is difficult.</p>
                                                            <p className="text">When clients work with unprofessional services, they frequently experience quality and variety concerns. Several businesses curate Q&A for specific topics alone. Several businesses offer these solutions for a variety of topics, but the content is rarely original. They incorporate pre-existing tests into the Q&A. To avoid any of these concerns, clients must work with organisations that have both competent SMEs and technical experts.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 flex-align-center">
                                                        <div className="img-single-small flat-text-center">
                                                            <img src="/images/courses/qa.jpeg" alt="nstructive" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="img-single-small flat-text-center">
                                                            <img src="images/courses/qa.jpg" alt="nstructive" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="text-wrap img-left clearfix">
                                                            <h6 className="title">Types of Q&A Services</h6>
                                                            <p className="text">Our team is made up of SMEs from diverse educational backgrounds. They have a lot of experience and can manage a lot of jobs at once. As a result, we provide a variety of Q&A solutions for a variety of themes and clientele.</p>
                                                            <ul className="list-skill">
                                                                <li> Multiple-Choice Questions (MCQ)</li>
                                                                <li> Short-Answer Questions</li>
                                                                <li> Essay Questions</li>
                                                                <li> Open Book Questions</li>
                                                                <li> Constructed Response Questions (CRQ)</li>
                                                                <li> Technology Enhanced Items (TEI)</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="text-wrap">
                                                            <h6 className="title">Addressing the Challenges of Providing High-Quality Q&A Services </h6>
                                                            <p className="text">One of the main challenges is the high volume of inquiries and questions that services companies receive. This can make it difficult to provide timely and accurate responses to each customer. As a result, some questions may go unanswered or receive a delayed response, which can negatively impact customer satisfaction and loyalty.</p>
                                                            <p className="text">Another challenge is ensuring that the responses provided are accurate, consistent, and aligned with the company's policies and values. Inaccurate or inconsistent responses can damage the company's reputation and erode trust among customers.Moreover, providing effective Q&A services requires a deep understanding of the company's products or services, policies, and procedures. This requires extensive training and expertise, which can be challenging to maintain, especially for large or complex organizations.</p>
                                                            <p className="text">Additionally, the need for multilingual support can be a significant challenge for services companies. Providing Q&A services in multiple languages requires specialized resources and expertise, which may not be readily available or cost-effective.</p>
                                                            <p className="text">In conclusion, providing effective Q&A services can be challenging for services companies due to various factors. By addressing the challenges of high volume inquiries, accuracy and consistency, expertise and training, multilingual support, and cost-effectiveness, services companies can improve their Q&A services and enhance customer satisfaction and loyalty.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="wrap-checkmark-tag clearfix">
                                                            <div className="tags one-of-two">
                                                                <Link to="#" className="tag-item bg-7ffe53">Q&A Service</Link>
                                                                <Link to="#" className="tag-item bg-fe5367">Cost Effective</Link>
                                                                <Link to="#" className="tag-item bg-536dfe">Multilingual</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-content">
                                            <div className="item-content">
                                                <div className="row">
                                                <div className="col-md-9">
                                                        <div className="text-wrap">
                                                            <h6 className="title">How Nstructive Can Assit?</h6>
                                                            <p className="text">One of the main advantages of Nstructive over other services companies is their focus on accuracy and consistency. Nstructive's team of experts is trained to provide accurate and consistent responses to customer inquiries, ensuring that customers receive the correct information and that the company's reputation is maintained.</p>
                                                            <p className="text">Nstructive's team of experts is also experienced in providing multilingual support, which sets them apart from other services companies. Nstructive understands the importance of providing Q&A services in multiple languages, and their team of experts is trained to provide support in various languages, ensuring that all customers can receive the support they need, regardless of their language.</p>
                                                            <p className="text">Furthermore, Nstructive's customized approach sets them apart from other services companies. Nstructive understands that each client has unique needs and challenges, so they work closely with them to create tailored Q&A solutions that address their specific pain points. This approach ensures that clients receive Q&A services that are relevant, practical, and actionable.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 flex-align-center">
                                                        <div className="img-single-small flat-text-center">
                                                            <img src="images/courses/qa_testing.webp" alt="nstructive" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="text-wrap">
                                                            <h6 className="title">Cost Effective</h6>
                                                            <p className="text">Nstructive's Q&A services are cost-effective, which is another advantage over other services companies. Nstructive understands that providing high-quality Q&A services can be expensive, and they work closely with clients to find cost-effective solutions that meet their needs and budget.</p>
                                                            <p className="text">Nstructive is a leading provider of high-quality Q&A services that sets them apart from other services companies. Their accuracy and consistency, customized approach, multilingual support, expertise and training, cost-effectiveness, and commitment to customer service make them an excellent choice for services companies looking to improve their Q&A services and enhance customer satisfaction and loyalty.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="wrap-checkmark-tag clearfix">
                                                            <div className="tags one-of-two">
                                                                <Link to="#" className="tag-item bg-7ffe53">Q&A Service</Link>
                                                                <Link to="#" className="tag-item bg-fe5367">Cost Effective</Link>
                                                                <Link to="#" className="tag-item bg-536dfe">Multilingual</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-content">
                                            <div className="item-content">
                                                <div className="row">
                                                    <div className="col-md-12"></div>
                                                </div>
                                            </div>
                                            {/* <div className="wrap-checkmark-tag clearfix">
                                                <div className="tags one-of-two">
                                                    <Link to="#" className="tag-item bg-7ffe53">Textbook Solution</Link>
                                                    <Link to="#" className="tag-item bg-fe5367">K12</Link>
                                                    <Link to="#" className="tag-item bg-536dfe">All Streams</Link>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
}

export default QaService;