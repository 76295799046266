import React, { Component } from 'react';
import {Link} from 'react-router-dom'

class TopBar extends Component {
    render() {
        return (
            <div className="top border-bt-d1d1ff style2">
                <div className="overlay" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="flat-info style2">
                                <ul>
                                    <li className="phone"><Link to="#">+91 789 571 0403</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 flat-text-right">
                            <div className="flat-info style2">
                                <ul>
                                    <li className="mail"><Link to="#">admin@nstructive.co.in</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        );
    }
}

export default TopBar;