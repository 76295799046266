import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Header,
  TopBar,
  Paramater,
  Footer,
  BottomBar,
  Loader,
} from "../layouts/general";

class Sitemap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          id: 1,
          names: "Sitemap",
        },
      ],
    };
  }

  render() {
    return (
      <div className="bg-body2">
        <div className="boxed">
          <Loader />
          <TopBar />
          {this.state.headers.map((data) => (
            <Header data={data} key={data.id} />
          ))}
          <section className="flat-title-page parallax parallax4 style2">
            <div className="overlay" />
            <div className="container wrap-title-page flat-text-center">
              <div className="title-page">
                <h2 className="title font-weight-700">
                  <Link to="#">Sitemap</Link>
                </h2>
              </div>
              <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/" onClick={() => { window.location.href = "" }}>Home</Link>
                  </li>
                  <li>
                    <Link to="/sitemap">Sitemap</Link>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          <div className="container section_container">
            <div className="section_container">
              <div className="parent-category">
                <div className="category-title">
                  <Link to="/" onClick={() => { window.location.href = "/" }}>Home</Link>
                </div>
              </div>
              <div className="parent-category">
                <div className="category-title">
                  <Link to="/about-us" onClick={() => { window.location.href = "/about-us" }}>About Us</Link>
                </div>
              </div>
              <div className="parent-category">
                <div className="category-title">
                  <Link to="/#">Service</Link>
                </div>
                <div className="child-categories">
                  <span>
                    <span className="child-title">
                      <Link to="/service?servicename=textbook-solution" onClick={() => { window.location.href = "/service?servicename=textbook-solution" }}>
                        Textbook Solution
                      </Link>
                    </span>
                  </span>
                  <span>
                    <span> | </span>
                    <span>
                      <Link to="/service?servicename=alt-text-writing" onClick={() => { window.location.href = "/service?servicename=alt-text-writing" }}>
                        Alt Text Writing
                      </Link>
                    </span>
                  </span>
                  <span>
                    <span> | </span>
                    <span>
                      <Link to="/service?servicename=qa-service" onClick={() => { window.location.href = "/service?servicename=qa-service" }}>
                        Q&A Services
                      </Link>
                    </span>
                  </span>
                  <span>
                    <span> | </span>
                    <span>
                      <Link to="/service?servicename=editorial" onClick={() => { window.location.href = "/service?servicename=editorial" }}>
                        Editorial
                      </Link>
                    </span>
                  </span>
                  <span>
                    <span> | </span>
                    <span>
                      <Link to="/service?servicename=assignment-help" onClick={() => { window.location.href = "/service?servicename=assignment-help" }}>
                        Assignment Help
                      </Link>
                    </span>
                  </span>
                  <span>
                    <span> | </span>
                    <span>
                      <Link to="/service?servicename=online-tutoring" onClick={() => { window.location.href = "/service?servicename=online-tutoring" }}>
                        Online Tutorial
                      </Link>
                    </span>
                  </span>
                </div>
              </div>
              <div className="parent-category">
                <div className="category-title">
                  <Link to="/contact-us" onClick={() => { window.location.href = "/contact-us" }}>Contact Us</Link>
                </div>
              </div>
              <div className="parent-category">
                <div className="category-title">
                  <Link to="/faq" onClick={() => { window.location.href = "/faq" }}>FAQ</Link>
                </div>
              </div>
              <div className="parent-category">
                <div className="category-title">
                  <Link to="/privacy-policy" onClick={() => { window.location.href = "/privacy-policy" }}>Privacy Policy</Link>
                </div>
              </div>
              <div className="parent-category">
                <div className="category-title">
                  <Link to="/terms-and-conditions" onClick={() => { window.location.href = "/terms-and-conditions" }}>Terms and Conditions</Link>
                </div>
              </div>
            </div>
          </div>

          <section className="flat-feature parallax parallax2">
            <div className="container">
              <Paramater />
            </div>
          </section>
          <Footer />
          <BottomBar />
        </div>
      </div>
    );
  }
}

export default Sitemap;
