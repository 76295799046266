import React, { Component } from 'react';
import { EventSingleComponent } from '../layouts/events';
import { Header, Footer, BottomBar, TopBar , Loader, Paramater} from '../layouts/general';
import { BannerTitle } from '../layouts/general/course';
class EventV1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Events'
                }
            ],
            titleBanner: [
                {
                    id: '1',
                    classnames: 'flat-title-page parallax parallax1',
                    classoverlay: 'overlay',
                    classbread: 'breadcrumbs',
                    titles:'Events',
                    description: undefined,
                    name01: 'Home', 
                    name02: 'Events',
                    link01: '/event-v1',
                    srcimg: 'images/title-page/bg-white.png',

                }
            ]
        }
    }
    render() {
        return (
            <div className="bg-body">
                <div className="boxed">
                <Loader />
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }
                    {
                        this.state.titleBanner.map(data => (
                            <BannerTitle data={data} key={data.id}/>
                        ))
                    }
                    <div className="content-wrap event-v1-page event-detail-page">
                        <div className="container">
                            <div className="content-page-wrap">
                                <EventSingleComponent />
                            </div>
                        </div>
                    </div>
                    <section className="flat-feature parallax parallax2">
                        <div className="container">
                            <Paramater />
                        </div>
                    </section>
                    <Footer />
                    <BottomBar />
                </div> 
            </div>
        );
    }
}

export default EventV1;