import { AltTextWriting, TextbookSolution, QaService, Editorial, AssignmentHelp, OnlineTutoring } from "../layouts/course";

const services = {
    textbooksolution: {
        name: 'Textbook Solution',
        description: 'Offering Accurate Textbook Solutions For All Academic Levels And Subjects.',
        component: <TextbookSolution />
    },
    alttextwriting: {
        name: 'Alt Text Writing',
        description: 'Examining images and providing individualised alt-texts.',
        component: <AltTextWriting />
    },
    qaservice: {
        name: 'Q&A Service',
        description: 'Accurate and engaging Q&A that improves students\' understanding.',
        component: <QaService />
    },
    editorial: {
        name: 'Editorial',
        description: 'Providing Relatively Inexpensive Copy Editing and Proofreading Services by Professional Proofreaders.',
        component: <Editorial />
    },
    assignmenthelp: {
        name: 'Assignment Help',
        description: 'Assignments Help is the finest option for all of your assignment needs, and you will never have to worry about your academics again.',
        component: <AssignmentHelp />
    },
    onlinetutoring: {
        name: 'Online Tutoring',
        description: 'For effective learning, we provide competent online tutors for all topics and grades.',
        component: <OnlineTutoring />
    }
};

export default services;