import React, { Component } from 'react';
import {Link} from 'react-router-dom'
class VideoComponent extends Component {
    render() {
        return (
            <section className="flat-video style2 parallax parallax13">
                <div className="overlay" />
                <div className="container">
                    <div className="video flat-text-center">
                        <div className="icon">
                            <a className="fancybox videobox-link" data-type="iframe"
                                href="https://www.youtube.com/embed/2Ge1GGitzLw?autoplay=1">
                                <span className="icon-video" />
                                <div className="videobox-animation circle-1" />
                                <div className="videobox-animation circle-2" />
                                <div className="videobox-animation circle-3" />
                            </a>
                        </div>
                        <div className="content">
                            <h2 className="title"><Link to="#">Discover our School Life</Link></h2>
                            <p className="description">We offer professional SEO services that help <Link to="#"
                                    className="link-site">websites increase</Link> their organic search score drastically in order to
                                compete for the highest rankings.</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default VideoComponent;