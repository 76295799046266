import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class BannerComponent extends Component {
    render() {
        return (
            <section className={this.props.data.classename01}>
                <div className="overlay" /> 
                <div className={this.props.data.classename02}>
                    <div className="title-page">
                        <h2 className="title font-weight-700"><Link to="#">{this.props.data.names}</Link></h2>
                    </div>
                    <div className="breadcrumbs style2">
                        <ul>
                            <li><Link to="/" onClick={() => {window.location.href="/"}}>Home</Link></li>
                            <li><Link to={this.props.data.links} onClick={() => {window.location.href=this.props.data.links}}>{this.props.data.names}</Link></li>
                        </ul>
                    </div>
                </div>
            </section>
        );
    }
}

export default BannerComponent;