import React, { Component } from 'react';
import { Link } from 'react-router-dom'
class SideBarLeft extends Component {
    render() {
        return (
            <div className="sidebar-left">
                <div className="widget-categories border-f-e6f3ff">
                    <h6 className="title-widget">DEVELOPMENT</h6>
                    <div className="content">
                        <ul>
                            <li><Link to="#">Illustration <span>(158)</span></Link></li>
                            <li><Link to="#">Design Skills <span>(155)</span></Link></li>
                            <li><Link to="#">Design Techniques <span>(111)</span></Link></li>
                            <li><Link to="#">Page Layout <span>(95)</span></Link></li>
                            <li><Link to="#">Projects <span>(64)</span></Link></li>
                            <li><Link to="#">Drawing <span>(60)</span></Link></li>
                            <li><Link to="#"> Typography <span>(56)</span></Link></li>
                            <li><Link to="#">Digital Painting <span>(55)</span></Link></li>
                            <li><Link to="#">Digital Publishing <span>(45)</span></Link></li>
                            <li><Link to="#">Design Business <span>(38)</span></Link></li>
                            <li><Link to="#">+ Show More</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="widget-categories border-f-e6f3ff mg-bottom-30">
                    <h6 className="title-widget">SOFTWARE</h6>
                    <div className="content">
                        <ul>
                            <li><Link to="#"> Adobe <span>(402)</span></Link></li>
                            <li><Link to="#">Photoshop <span>(159)</span></Link></li>
                            <li><Link to="#">Illustrator <span>(143)</span></Link></li>
                            <li><Link to="#">InDesign <span>(109)</span></Link></li>
                            <li><Link to="#">Corel <span>(11)</span></Link></li>
                            <li><Link to="#">Painter <span>(9)</span></Link></li>
                            <li><Link to="#">Acrobat <span>(8)</span></Link></li>
                            <li><Link to="#">Open<span> Source (8)</span></Link></li>
                            <li><Link to="#">Apple <span>(7)</span></Link></li>
                            <li><Link to="#">Amazon <span>(6)</span></Link></li>
                            <li><Link to="#">+ Show More</Link></li>
                        </ul>
                    </div>
                </div>
                <div className="widget-subscribe flat-text-center">
                    <h4 className="widget-title ">Subscribe Newslatter </h4>
                    <p className="text">Netus et malesuad fames ac tmgest blandi cursus risuar etiam non puam lacus. </p>
                    <p className="over font-Freestyle">Over </p>
                    <p className="wrap-over"><span className="count  font-Freestyle">20,640</span> <span className="free"> Free
                            Tutorial</span></p>
                    <div className="wrap-description">
                        <p className="description">Develop in-demand skills with access to thousands of expert-led courses on
                            business, tech and creative topics.</p>
                    </div>
                    <div className="wrap-form-subscribe">
                        <form>
                            <input type="email" name="email" placeholder="Enter your Email" />
                            <button className="flat-button btn-send v2">SEND</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default SideBarLeft;