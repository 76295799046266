import React, { Component } from 'react';

class TabCourses extends Component {
    render() {
        return (
            <div>
                {/* <ul className="tab-title clearfix style4">
                    <li className="item-title  overview">
                        <span className="inner">QUALITY</span>
                    </li>
                    <li className="item-title event">
                        <span className="inner">ON-TIME DELIVERY</span>
                    </li>
                    <li className="item-title requirements">
                        <span className="inner">LARGE SCALE</span>
                    </li>
                    <li className="item-title review">
                        <span className="inner">LOW COST</span>
                    </li>
                </ul> */}
                <div className="tab-content-wrap flat-text-justify">
                    <h6 className="pre-title color-2ba2db">WHY CHOOSE US</h6>
                    <h3 className="heading">Experience excellence with us</h3>
                    <p className="description">Nstructive is known worldwide for its world-class educational content. It is the product of our thorough planning, hard work, learning, and years of expertise delivering quality information to students all around the world.</p>
                    <div className="tab-content ">
                        <div className="item-content">
                            <div className="row">
                                <div className="col-sm-1 icon-box style1">
                                    <div className="iconbox-icon">
                                        <span className="icon-book" />
                                    </div>
                                </div>
                                <div className="col-sm-11">
                                    <div className="text-wrap img-right clearfix">
                                        <h6 className="title">
                                            QUALITY
                                        </h6>
                                        <p className="text">
                                            The workforce at Nstructive is made up of highly educated and experienced item writers. We are able to supply quality on a constant basis thanks to a strict project management and quality assurance approach.
                                        </p>
                                        {/* <h6 className="title mg-bottom-25">
                                            INSIGHTS FROM EDUCATORS
                                        </h6>
                                        <p className="text">
                                            Educators are looking for interesting, hands-on, interactive curriculum that allows students to fully participate and think critically throughout the learning process. It is critical for educators that digital content be validated by a reliable source. It is also critical to provide teachers with a diverse set of high-quality resources, as well as research-based instructional practises.
                                        </p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content ">
                        <div className="item-content">
                            <div className="row">
                                    <div className="col-sm-1 icon-box style1">
                                        <div className="iconbox-icon">
                                            <span className="icon-key" />
                                        </div>
                                    </div>
                                    <div className="col-sm-11">
                                        <div className="text-wrap img-right clearfix">
                                            <h6 className="title">
                                                ON-TIME DELIVERY
                                            </h6>
                                            <p className="text">
                                                Nstructive strives to complete all projects on time. We reduce costly delays by proactively addressing potential risks and issues throughout the project's lifecycle.
                                            </p>
                                            {/* <h6 className="title mg-bottom-25">
                                                Can a smarter transformation of education transform society?
                                            </h6>
                                            <p className="text">
                                                Education is undergoing a dramatic shift as educators collaborate with IT professionals to redesign systems to better prepare school-age pupils and lifelong learners for the digital economy.<br />
                                                “Education is a human right with enormous transformative power,” said renowned former UN Secretary General Kofi Annan. However, education is undergoing a huge transition. That's a good thing, because the world for which it trains us has changed drastically.<br />
                                                Educators and their IT departments are creating the systems that educate us to engage with the world, overhauling education to prepare everyone, throughout their lives, to participate in our digital economy, with the help of smarter technology.
                                            </p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="tab-content ">
                        <div className="item-content">
                            <div className="row">
                                <div className="col-sm-1 icon-box style1">
                                    <div className="iconbox-icon">
                                        <span className="icon-people" />
                                    </div>
                                </div>
                                <div className="col-sm-11">
                                    <div className="text-wrap img-right clearfix">
                                        <h6 className="title">
                                            LARGE SCALE
                                        </h6>
                                        <p className="text">
                                            Nstructive employs a full-time staff of item authors, visual designers, item quality analysts, and project managers. Nstructive has a network of over 100+ freelancers item writers and numerous small certified subcontractors in addition to full-time workers, allowing us to take on major projects.
                                        </p>
                                        {/* <h6 className="title mg-bottom-25">
                                            CONSTANTLY HIRING
                                        </h6>
                                        <p className="text">
                                            Nstructive is constantly expanding and tries to hire as many subject matter experts as possible in order to provide even more quality and quantity to its clients.
                                        </p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content ">
                        <div className="item-content">
                            <div className="row">
                                    <div className="col-sm-1 icon-box style1">
                                        <div className="iconbox-icon">
                                            <span className="icon-key" />
                                        </div>
                                    </div>
                                    <div className="col-sm-11">
                                    <div className="text-wrap img-right clearfix">
                                        <h6 className="title">
                                            LOW COST
                                        </h6>
                                        <p className="text">
                                            Nstructive can assist you in meeting your assessment budget. Please contact us for an estimate on one of our many options at a reasonable price.
                                        </p>
                                        {/* <h6 className="title mg-bottom-25">
                                            POSITION YOUR VALUE NOT YOUR PRICE
                                        </h6>
                                        <p className="text">
                                            Cost is definitely important to potential online students, according to statistics like these. To get an advantage in this competitive educational climate, institutions should examine the following choices for providing potential students with affordable learning solutions.
                                        </p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TabCourses;