const dataTab = [
    {
        id: 1,
        content: [
            {
                id: 1,
                classnames: 'course  text-2ebd59  Leadership',
                imgsrc: 'images/courses/courses-gird-v1-6.jpg',
                title: 'Angular 6 - The Complete Guide',
                teacher: 'Rosy Janner',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$49.00'
            },
            {
                id: 2,
                classnames: 'course  text-2ebd59  Design',
                imgsrc: 'images/courses/courses-gird-v1-7.jpg',
                title: 'Complete Java Masterclass',
                teacher: 'Tom Steven',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$24.00'
            }, 
            {
                id: 3,
                classnames: 'course  text-2ebd59 Leadership',
                imgsrc: 'images/courses/courses-gird-v1-8.jpg',
                title: 'React 16 - The Complete Guide',
                teacher: 'Rosy Janner',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$89.00'
            },       
            {
                id: 4,
                classnames: 'course  text-2ebd59 Design',
                imgsrc: 'images/courses/courses-gird-v1-9.jpg',
                title: 'Node.js Developer Course',
                teacher: 'Ana Murphy',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: 'FREE'
            }, 
            {
                id: 5,
                classnames: 'course  text-ea0042 Leadership Photography',
                imgsrc: 'images/courses/courses-gird-v1-10.jpg',
                title: 'The Ultimate Drawing Course',
                teacher: 'Rosy Janner',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$49.00'
            }, 
            {
                id: 6,
                classnames: 'course   text-c100ea Business Leadership Photography',
                imgsrc: 'images/courses/courses-gird-v1-11.jpg',
                title: 'Learn Python & Ethical Hacking',
                teacher: 'Tom Steven',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$24.00'
            }, 
            {
                id: 7,
                classnames: 'course  text-256cff Photography',
                imgsrc: 'images/courses/courses-gird-v1-12.jpg',
                title: 'Advanced CSS Flexbox',
                teacher: 'TMark Henrey',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: 'FREE'
            }
        ]
    },
    {
        id: 2,
        content: [
            {
                id: 1,
                classnames: 'course  text-c100ea Leadership',
                imgsrc: 'images/courses/courses-gird-v1-6.jpg',
                title: 'Angular 6 - The Complete Guide',
                teacher: 'Rosy Janner',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$49.00'
            },
            {
                id: 2,
                classnames: 'course  text-ea0042 Design',
                imgsrc: 'images/courses/courses-gird-v1-7.jpg',
                title: 'Complete Java Masterclass',
                teacher: 'Tom Steven',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$24.00'
            }, 
            {
                id: 3,
                classnames: 'course  text-2ebd59 Leadership',
                imgsrc: 'images/courses/courses-gird-v1-8.jpg',
                title: 'React 16 - The Complete Guide',
                teacher: 'Rosy Janner',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$89.00'
            },       
            {
                id: 4,
                classnames: 'course  text-2ebd59 Design',
                imgsrc: 'images/courses/courses-gird-v1-9.jpg',
                title: 'Node.js Developer Course',
                teacher: 'Ana Murphy',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: 'FREE'
            }, 
            {
                id: 5,
                classnames: 'course  text-ea0042 Leadership Photography',
                imgsrc: 'images/courses/courses-gird-v1-10.jpg',
                title: 'The Ultimate Drawing Course',
                teacher: 'Rosy Janner',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$49.00'
            }, 
            {
                id: 6,
                classnames: 'course   text-c100ea Business Leadership Photography',
                imgsrc: 'images/courses/courses-gird-v1-11.jpg',
                title: 'Learn Python & Ethical Hacking',
                teacher: 'Tom Steven',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$24.00'
            }, 
            {
                id: 7,
                classnames: 'course  text-256cff Photography',
                imgsrc: 'images/courses/courses-gird-v1-12.jpg',
                title: 'Advanced CSS Flexbox',
                teacher: 'TMark Henrey',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: 'FREE'
            }
        ]
    },
    {
        id: 3,
        content: [
            {
                id: 1,
                classnames: 'course  text-c100ea Leadership',
                imgsrc: 'images/courses/courses-gird-v1-6.jpg',
                title: 'Angular 6 - The Complete Guide',
                teacher: 'Rosy Janner',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$49.00'
            },
            {
                id: 2,
                classnames: 'course  text-ea0042 Design',
                imgsrc: 'images/courses/courses-gird-v1-7.jpg',
                title: 'Complete Java Masterclass',
                teacher: 'Tom Steven',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$24.00'
            }, 
            {
                id: 3,
                classnames: 'course  text-2ebd59 Leadership',
                imgsrc: 'images/courses/courses-gird-v1-8.jpg',
                title: 'React 16 - The Complete Guide',
                teacher: 'Rosy Janner',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$89.00'
            },       
            {
                id: 4,
                classnames: 'course  text-2ebd59 Design',
                imgsrc: 'images/courses/courses-gird-v1-9.jpg',
                title: 'Node.js Developer Course',
                teacher: 'Ana Murphy',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: 'FREE'
            }
        ]
    },
    {
        id: 4,
        content: [
            {
                id: 1,
                classnames: '"course  text-c100ea Leadership',
                imgsrc: 'images/courses/courses-gird-v1-6.jpg',
                title: 'Angular 6 - The Complete Guide',
                teacher: 'Rosy Janner',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$49.00'
            },
            {
                id: 2,
                classnames: 'course  text-ea0042 Design',
                imgsrc: 'images/courses/courses-gird-v1-7.jpg',
                title: 'Complete Java Masterclass',
                teacher: 'Tom Steven',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$24.00'
            }, 
            {
                id: 3,
                classnames: 'course  text-ea0042 Leadership Photography',
                imgsrc: 'images/courses/courses-gird-v1-10.jpg',
                title: 'he Ultimate Drawing Course',
                teacher: 'Rosy Janner',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$49.00'
            },       
            {
                id: 4,
                classnames: 'course   text-c100ea Business Leadership Photography',
                imgsrc: 'images/courses/courses-gird-v1-11.jpg',
                title: 'Learn Python & Ethical Hacking',
                teacher: 'Tom Steven',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$24.00'
            }, 
            {
                id: 5,
                classnames: 'course  text-256cff Photography',
                imgsrc: 'images/courses/courses-gird-v1-12.jpg',
                title: 'Advanced CSS Flexbox',
                teacher: 'TMark Henrey',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: 'FREE'
            }
        ]
    },
    {
        id: 5,
        content: [
            {
                id: 1,
                classnames: 'course  text-c100ea Leadership',
                imgsrc: 'images/courses/courses-gird-v1-6.jpg',
                title: 'Angular 6 - The Complete Guide',
                teacher: 'Rosy Janner',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$49.00'
            },
            {
                id: 2,
                classnames: 'course  text-ea0042 Design',
                imgsrc: 'images/courses/courses-gird-v1-7.jpg',
                title: 'Complete Java Masterclass',
                teacher: 'Tom Steven',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$24.00'
            }, 
            {
                id: 3,
                classnames: 'course  text-2ebd59 Leadership',
                imgsrc: 'images/courses/courses-gird-v1-8.jpg',
                title: 'React 16 - The Complete Guide',
                teacher: 'Rosy Janner',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$89.00'
            }
        ]
    },
    {
        id: 6,
        content: [
            {
                id: 1,
                classnames: 'course  text-c100ea Leadership',
                imgsrc: 'images/courses/courses-gird-v1-6.jpg',
                title: 'Angular 6 - The Complete Guide',
                teacher: 'Rosy Janner',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$49.00'
            },
            {
                id: 2,
                classnames: 'course  text-ea0042 Design',
                imgsrc: 'images/courses/courses-gird-v1-7.jpg',
                title: 'Complete Java Masterclass',
                teacher: 'Tom Steven',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$24.00'
            }, 
            {
                id: 3,
                classnames: 'course  text-2ebd59 Leadership',
                imgsrc: 'images/courses/courses-gird-v1-8.jpg',
                title: 'React 16 - The Complete Guide',
                teacher: 'Rosy Janner',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: '$89.00'
            },       
            {
                id: 4,
                classnames: 'course  text-256cff Photography',
                imgsrc: 'images/courses/courses-gird-v1-12.jpg',
                title: 'Advanced CSS Flexbox',
                teacher: 'TMark Henrey',
                description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
                count: '5.0 (980)',
                price: 'FREE'
            }
        ]
    },
]

export default dataTab;