import React, { Component } from 'react';
import { Link } from 'react-router-dom'
class EventV1Component extends Component {
    render() {
        return (
            <div className="flat-events style1">
                <div className="event style1 v1">
                    <div className="row">
                        <div className=" col-lg-7 col-md-12">
                            <div className="event-img">
                                <Link to="#"><img src="images/events/event-grid-1.jpg" alt="nstructive" /></Link>
                                <div className="meta-date flat-text-center">
                                    <span className="day">06</span>
                                    <span className="month">DECEMBER</span>
                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-5 col-md-12">
                            <div className="event-content">
                                <div className="meta-datetime">
                                    <ul className="list">
                                        <li><Link to="#">Sunday</Link></li>
                                        <li><Link to="#">December 06</Link></li>
                                        <li><Link to="#">9.00 am</Link></li>
                                    </ul>
                                </div>
                                <div className="content">
                                    <h3 className="title"><Link to="/event-single" onClick={() => {window.location.href="/event-single"}}>September Undergraduate Open House</Link></h3>
                                    <p className="description">Lorem Ipsn gravida nibh velalito auctor alipuet. Aenean sollicitudin,
                                        lorem quis bibendum auci elit conse ipsutis sem nibh id elit. Duis sed odio sit amet nibh
                                        vulputate cursus.</p>
                                </div>
                                <div className="author clearfix">
                                    <div className="author-avata">
                                        <img src="images/events/author-teacher.jpg" alt="nstructive" />
                                    </div>
                                    <div className="author-info">
                                        <h4 className="name"><Link to="#">Charlotte H. Bronk</Link></h4>
                                        <p className="text">Founder and Сhairman at Global Innovation Catalyst</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="event style1 v2">
                    <div className="row">
                        <div className=" col-lg-5 col-md-12">
                            <div className="event-content">
                                <div className="meta-datetime">
                                    <ul className="list">
                                        <li><Link to="#">Sunday</Link></li>
                                        <li><Link to="#">December 09</Link></li>
                                        <li><Link to="#">9.00 am</Link></li>
                                    </ul>
                                </div>
                                <div className="content">
                                    <h3 className="title"><Link to="/event-single" onClick={() => {window.location.href="/event-single"}}>Tales from the White House and beyond</Link></h3>
                                    <p className="description">Lorem Ipsn gravida nibh velalito auctor alipuet. Aenean sollicitudin,
                                        lorem quis bibendum auci elit conse ipsutis sem nibh id elit. Duis sed odio sit amet nibh
                                        vulputate cursus.</p>
                                </div>
                                <div className="author clearfix">
                                    <div className="author-avata">
                                        <img src="images/events/author-teacher.jpg" alt="nstructive" />
                                    </div>
                                    <div className="author-info">
                                        <h4 className="name"><Link to="#">Charlotte H. Bronk</Link></h4>
                                        <p className="text">Founder and Сhairman at Global Innovation Catalyst</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-7 col-md-12">
                            <div className="event-img">
                                <Link to="#"><img src="images/events/event-grid-2.jpg" alt="nstructive" /></Link>
                                <div className="meta-date flat-text-center">
                                    <span className="day">09</span>
                                    <span className="month">DECEMBER</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="event style1 v1">
                    <div className="row">
                        <div className=" col-lg-7 col-md-12">
                            <div className="event-img">
                                <Link to="#"><img src="images/events/event-grid-3.jpg" alt="nstructive" /> </Link>
                                <div className="meta-date flat-text-center">
                                    <span className="day">12</span>
                                    <span className="month">DECEMBER</span>
                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-5 col-md-12">
                            <div className="event-content">
                                <div className="meta-datetime">
                                    <ul className="list">
                                        <li><Link to="#">Sunday</Link></li>
                                        <li><Link to="#">December 12</Link></li>
                                        <li><Link to="#">9.00 am</Link></li>
                                    </ul>
                                </div>
                                <div className="content">
                                    <h3 className="title"><Link to="/event-single" onClick={() => {window.location.href="/event-single"}}>Creating Concrete Universes Gloria Anzaldúa </Link></h3>
                                    <p className="description">Lorem Ipsn gravida nibh velalito auctor alipuet. Aenean sollicitudin,
                                        lorem quis bibendum auci elit conse ipsutis sem nibh id elit. Duis sed odio sit amet nibh
                                        vulputate cursus.</p>
                                </div>
                                <div className="author clearfix">
                                    <div className="author-avata">
                                        <img src="images/events/author-teacher.jpg" alt="nstructive" />
                                    </div>
                                    <div className="author-info">
                                        <h4 className="name"><Link to="#">Charlotte H. Bronk</Link></h4>
                                        <p className="text">Founder and Сhairman at Global Innovation Catalyst</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="event style1 v2">
                    <div className="row">
                        <div className=" col-lg-5 col-md-12">
                            <div className="event-content">
                                <div className="meta-datetime">
                                    <ul className="list">
                                        <li><Link to="#">Sunday</Link></li>
                                        <li><Link to="#">December 15</Link></li>
                                        <li><Link to="#">9.00 am</Link></li>
                                    </ul>
                                </div>
                                <div className="content">
                                    <h3 className="title"><Link to="/event-single" onClick={() => {window.location.href="/event-single"}}>Inspiration Memento" Live Commission Art</Link></h3>
                                    <p className="description">Lorem Ipsn gravida nibh velalito auctor alipuet. Aenean sollicitudin,
                                        lorem quis bibendum auci elit conse ipsutis sem nibh id elit. Duis sed odio sit amet nibh
                                        vulputate cursus.</p>
                                </div>
                                <div className="author clearfix">
                                    <div className="author-avata">
                                        <img src="images/events/author-teacher.jpg" alt="nstructive" />
                                    </div>
                                    <div className="author-info">
                                        <h4 className="name"><Link to="#">Charlotte H. Bronk</Link></h4>
                                        <p className="text">Founder and Сhairman at Global Innovation Catalyst</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-12">
                            <div className="event-img">
                                <Link to="#"><img src="images/events/event-grid-4.jpg" alt="nstructive" /></Link>
                                <div className="meta-date flat-text-center">
                                    <span className="day">15</span>
                                    <span className="month">DECEMBER</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EventV1Component;