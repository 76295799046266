import React, { Component } from 'react';

class BottomBar extends Component {
    render() {
        return (
            <div className="bottom bg-15222e">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="copyright flat-text-center">
                                <p>© Copyright 2023 <b>Nstructive Global Services Private Limited</b>. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BottomBar;