import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class BannerProfessional extends Component {
    render() {
        return (
            <div className="service-wrap flat-text-center">
                <h2 className="title"><Link to="#">Start My Free Month!</Link></h2>
                <p className="description">We offer professional SEO services that help websites <Link to="#" className="link-site">increase their organic search</Link> score drastically in order to compete for the highest rankings.</p>
                <div className="wrap-btn">
                    <Link to="#" className="flat-button btn-start border-ra4">START MY FREE MONTH</Link>
                </div>
            </div>
        );
    }
}

export default BannerProfessional;