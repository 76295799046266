const couresBox = [
    {
        id: 1,
        classnames: 'course style2 one-of-three text-2ebd59 effect-animation',
        dataanimate: 'zoomIn',
        delay: '0.2s',
        offset: '75%',
        srcimg: 'images/courses/courses-index-1.jpg',
        title: 'Build A Full Web Chat App',
        teacher: 'Ana Murphy',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star',
        ratting: '5.0 (980)',
        pricing: 'FREE'
    },
    {
        id: 2,
        classnames: 'course style2 one-of-three text-ea0042 effect-animation',
        dataanimate: 'zoomIn',
        delay: '0.4s',
        offset: '75%',
        srcimg: 'images/courses/courses-index-2.jpg',
        title: 'Complete JavaScript Course',
        teacher: 'Rosy Janner',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star-half',
        ratting: '4.7 (2,632)',
        pricing: '$49.00'
    },
    {
        id: 3,
        dataanimate: 'zoomIn',
        delay: '0.6s',
        offset: '75%',
        classnames: 'course style2 one-of-three text-c100ea effect-animation',
        srcimg: 'images/courses/courses-index-3.jpg',
        title: 'Learning Python Data Analysis',
        teacher: 'Tom Steven',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star-half',
        ratting: '4.8 (7,982)',
        pricing: '$39.00'
    }
]

export default couresBox;