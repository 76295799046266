import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Header,
  TopBar,
  Paramater,
  Footer,
  BottomBar,
  Loader,
} from "../layouts/general";
import emailjs from "@emailjs/browser";

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          id: 1,
          names: "CONTACT US",
        },
      ],
      isEmailRequestSuccessful: false,
      isEmailRequestFailed: false,
    };
  }

  emailRequestFailed = (error) => {
    this.setState({
      isEmailRequestFailed: true,
      isEmailRequestSuccessful: false,
    });
    console.log(error);
  };

  sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_t44cwxe",
        "template_9k7zpvl",
        e.target,
        "wNySJiwqTLDjtRSAU"
      )
      .then(
        (result) => {
          if (result && result.status === 200) {
            this.setState({
              isEmailRequestSuccessful: true,
              isEmailRequestFailed: false,
            });
          } else {
            this.emailRequestFailed(result);
          }
        },
        (error) => this.emailRequestFailed(error)
      );
  };

  render() {
    return (
      <div className="bg-body2">
        <div className="boxed">
          <Loader />
          <TopBar />
          {this.state.headers.map((data) => (
            <Header data={data} key={data.id} />
          ))}
          <section className="flat-title-page parallax parallax4 style2">
            <div className="overlay" />
            <div className="container wrap-title-page flat-text-center">
              <div className="title-page">
                <h2 className="title font-weight-700">
                  <Link to="#">Contact Us</Link>
                </h2>
              </div>
              <div className="breadcrumbs style2">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="flat-register-now">
            <div className="overlay"></div>
            <div className="content-wrap faqs-page">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 nop">
                    <div className="contact-bg">
                      <h3 className="title">Nstructive Global Services Ptd. Ltd.</h3>
                      <address>
                        <ul>
                          <li>
                            <i className="fas fa-map-marker-alt"></i>Kutub Khana
                            Chauraha, Near Bhaji Itrwaley 0/62,
                            <br />
                            Bareilly - 243001, Uttar Pradesh.
                          </li>
                          <li>
                            <i className="fas fa-mobile-alt"></i> +91 789 571 0403
                          </li>
                        </ul>
                      </address>
                      <address>
                        <h3 className="title">For careers</h3>
                        <ul>
                          <li>
                            <i className="far fa-envelope"></i>
                            hr@nstructive.co.in
                          </li>
                        </ul>
                      </address>
                      <address>
                        <h3 className="title">For business and other inquiries</h3>
                        <ul>
                          <li>
                            <i className="far fa-envelope"></i>
                            admin@nstructive.co.in
                          </li>
                        </ul>
                      </address>
                      <ul className="social-links">
                        <li>
                          <a
                            href="https://www.linkedin.com/company/nstructive-global-services-private-limited/"
                            target="blank"
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.facebook.com/Nstructive-Global-Services-Private-Limited-108692798250362"
                            target="blank"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instragam.com/nstructive"
                            target="blank"
                          >
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                      <p className="quote">
                        "Connection is the essence of humanity. Get in touch to
                        build meaningful relationships, collaborate, and create
                        something extraordinary."
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 nop">
                    <div className="wrap-form flat-text-center">
                      <div className="heading">
                        <h6 className="title">GET IN TOUCH</h6>
                      </div>
                      {this.state.isEmailRequestSuccessful ? (
                        <p className="alert alert-success">Request Submitted Successfully.</p>
                      ) : null}
                      {this.state.isEmailRequestFailed ? (
                        <p className="alert alert-danger">
                          Something went wrong. Request failed.
                        </p>
                      ) : null}
                      <form onSubmit={this.sendEmail}>
                        <div className="wrap-input">
                          <input
                            type="text"
                            name="name"
                            placeholder="Your name"
                          />
                        </div>
                        <div className="wrap-input">
                          <input
                            type="email"
                            name="email"
                            placeholder="Your email address"
                          />
                        </div>
                        <div className="wrap-input">
                          <input
                            type="text"
                            name="phone"
                            placeholder="Your phone number"
                          />
                        </div>
                        <div className="wrap-input last-child">
                          <textarea
                            type="text"
                            name="description"
                            rows="3"
                            placeholder="About you (include preferred subject as well)"
                          />
                        </div>
                        <div className="wrap-btn">
                          <button className="flat-button btn-apply">
                            SUBMIT
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="flat-feature parallax parallax2">
            <div className="container">
              <Paramater />
            </div>
          </section>
          <Footer />
          <BottomBar />
        </div>
      </div>
    );
  }
}

export default ContactUs;
