import React, { Component } from "react";
import emailjs from "@emailjs/browser";

class FormApply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmailRequestSuccessful: false,
      isEmailRequestFailed: false,
    };
  }

  emailRequestFailed = (error) => {
    this.setState({
      isEmailRequestFailed: true,
      isEmailRequestSuccessful: false,
    });
    console.log(error);
  };

  sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_t44cwxe",
        "template_9k7zpvl",
        e.target,
        "wNySJiwqTLDjtRSAU"
      )
      .then(
        (result) => {
          if (result && result.status === 200) {
            this.setState({
              isEmailRequestSuccessful: true,
              isEmailRequestFailed: false,
            });
          } else {
            this.emailRequestFailed(result);
          }
        },
        (error) => this.emailRequestFailed(error)
      );
  };

  render() {
    return (
      <div className="wrap-form flat-text-center">
        <p className="free">Earn Money While Learning!</p>
        <div className="heading">
          <h6 className="title"> START CAREER WITH NSTRUCTIVE </h6>
          <p className="description">
            Join forces with us to advance your career in education industry.
          </p>
        </div>
        {this.state.isEmailRequestSuccessful ? (
          <p className="alert alert-success">Request Submitted Successfully.</p>
        ) : null}
        {this.state.isEmailRequestFailed ? (
          <p className="alert alert-danger">
            Something went wrong. Request failed.
          </p>
        ) : null}
        <form onSubmit={this.sendEmail}>
          <div className="wrap-input">
            <input type="text" name="name" placeholder="Your name" />
          </div>
          <div className="wrap-input">
            <input type="email" name="email" placeholder="Your email address" />
          </div>
          <div className="wrap-input">
            <input type="text" name="phone" placeholder="Your phone number" />
          </div>
          <div className="wrap-input last-child">
            <textarea
              type="text"
              name="description"
              rows="3"
              placeholder="About you (include preferred subject as well)"
            />
          </div>
          <div className="wrap-btn">
            <button className="flat-button btn-apply">APPLY NOW</button>
          </div>
        </form>
      </div>
    );
  }
}

export default FormApply;
