import React from 'react';
import { Link } from 'react-router-dom'
import { Header, TopBar, Footer, BottomBar, Loader } from '../layouts/general';

const headers = [
    {
        id: 1,
        names: 'Not Found',
    },
];

function NotFound() {
        return (
            <div className="bg-body2">
                <div className="boxed">
                    <Loader />
                    <TopBar />
                    {
                        headers.map(data => (
                            <Header data={data} key={data.id} />
                        ))
                    }
                    <section className="page_404">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12 ">
                                    <div className="text-center">
                                        <h1 className="text-center">404</h1>
                                        <div className="four_zero_four_bg" />
                                        <div className="contant_box_404">
                                            <h3 className="h2">
                                                Looks like you're lost.
                                            </h3>
                                            <p>The page you are looking for is not available!</p>
                                            <Link to="" className="flat-button btn-contact">Go to Home</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                    <BottomBar />
                </div>
            </div>
        );
    }

export default NotFound;