import React, { Component } from 'react';
import {Link} from 'react-router-dom'
class UpcomingEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataEvents: [
                {
                    id: 1,
                    srcimg: 'images/events/event-index2-1.png',
                    titles: 'Hands On Traning Workshop',
                    description: 'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
                    address: '30 TIMES SQUARE',
                    time: '16 : 00',
                    classnames: 'meta-date flat-text-center bg-0046d4',
                    day: '25',
                    month: 'JUNE'
                },
                {
                    id: 2,
                    srcimg: 'images/events/event-index2-2.png',
                    titles: 'Sports Event Highlights',
                    description: 'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
                    address: 'VENICE, ITALY',
                    time: '16 : 00',
                    classnames: 'meta-date flat-text-center bg-ffa81e',
                    day: '12',
                    month: 'JULY'
                },
                {
                    id: 3,
                    srcimg: 'images/events/event-index2-3.png',
                    titles: 'Winter Sports Camp',
                    description: 'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
                    address: 'STATE PARK, CA ',
                    time: '16 : 00',
                    classnames: 'meta-date flat-text-center bg-f64260',
                    day: '16',
                    month: 'JUNE'
                }
            ]
        }
    }
    
    render() {
        return (
            <div className="flat-events style3">
                <div className="row">                  
                    {
                        this.state.dataEvents.map(data => (
                            <div className="col-lg-4 col-md-12 col-sm-12" key={data.id} >
                                <div className="event style3  flat-text-center">
                                    <div className="event-img">
                                        <Link to="#"><img src={data.srcimg} alt="nstructive" /></Link>
                                    </div>
                                    <div className="event-content">
                                        <h4 className="title"><Link to="/event-single" onClick={() => {window.location.href="/event-single"}}>{data.titles} </Link></h4>
                                        <p className="description">{data.description} </p>
                                        <div className="meta-list">
                                            <ul className="list">
                                                <li className="address"><Link to="#">{data.address} </Link></li>
                                                <li className="time"><Link to="#">{data.time}</Link></li>
                                            </ul>
                                        </div>
                                        <div className={data.classnames}>
                                            <p className="day">{data.day} </p>
                                            <p className="month">{data.month} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        );
    }
}

export default UpcomingEvent;