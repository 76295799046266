import React, { Component } from 'react';
import {ListBlogComponent, SidebarBlog} from '../layouts/blog';
import { Header, TopBar , Paramater, Footer, BottomBar, Loader } from '../layouts/general';
import { BannerComponent } from '../layouts/pages';

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Blog',
                }
            ],
            dataBanner: [
                {
                    id: 1,
                    classename01: 'flat-title-page parallax parallax16 style5',
                    classename02: 'container wrap-title-page flat-text-center',
                    links: '/blog',
                    names: 'Blog'
                }
            ]
        }
    }
    render() {
        return (
            <div className="bg-body">
                <div className="boxed">
                <Loader />
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }
                    {
                        this.state.dataBanner.map(data => (
                            <BannerComponent data={data} key={data.id}/>
                        ))
                    }
                    <div className="content-wrap blog-page">
                        <div className="container">  
                            <div className="row">
                                <ListBlogComponent />
                                <SidebarBlog />
                            </div>
                        </div>
                    </div>
                    <section className="flat-feature parallax parallax2">
                        <div className="container">
                            <Paramater />
                        </div>
                    </section>
                    <Footer />
                    <BottomBar />
                </div>
            </div>
        );
    }
}

export default Blog;