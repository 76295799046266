import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class BannerProfessional extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-md-7">
                    <div className="service-wrap ">
                        <h2 className="title">
                            <Link to="#">Start My <span>Free Month!</span></Link>
                        </h2>
                        <p className="description">We offer professional SEO services that help
                            <Link to="#" className="link-site v2">websites increase</Link> their organic search score. </p>
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="wrap-btn flat-text-right">
                        <Link to="#" className="flat-button btn-start border-ra4 v2">START MY FREE MONTH</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default BannerProfessional;