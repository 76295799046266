import Home01 from './Home01'
import Home02 from './Home02'
import Home03 from './Home03'
import Home04 from './Home04'
import HomeAnimate from './HomeAnimate'
import CourseV1 from './CourseV1'
import CourseV2 from './CourseV2'
import CourseV3 from './CourseV3'
import CourseSingle from './CourseSingle'
import IntructorPage from './IntructorPage'
import EventV1 from './EventV1'
import EventV2 from './EventV2'
import EventSingle from './EventSingle'
import Faqs from './Faqs'
import AboutUs from './AboutUs'
import Shop from './Shop'
import ShopSingle from './ShopSingle'
import Blog from './Blog'
import BlogSingle from './BlogSingle'
import ContactUs from './ContactUs'
import Privacy from './Privacy'
import Terms from './Terms'
import NotFound from './NotFound'
import Sitemap from './Sitemap'

const routes = [
    { id: 1, path: '/', component: Home03},
    { id: 2, path: '/home-01', component: Home01},
    { id: 3, path: '/home-02', component: Home02},
    { id: 4, path: '/home-03', component: Home03},
    { id: 5, path: '/home-04', component: Home04},
    { id: 6, path: '/home-animate', component: HomeAnimate},
    { id: 7, path: '/courses-grid-v1', component: CourseV1},
    { id: 8, path: '/courses-grid-v2', component: CourseV2},
    { id: 9, path: '/courses-grid-v3', component: CourseV3},
    { id: 10, path: '/courses-single', component: CourseSingle},
    { id: 11, path: '/instructors', component: IntructorPage},
    { id: 12, path: '/event-v1', component: EventV1},
    { id: 13, path: '/event-v2', component: EventV2},
    { id: 14, path: '/event-single', component: EventSingle},
    { id: 15, path: '/faq', component: Faqs},
    { id: 16, path: '/about-us', component: AboutUs},
    { id: 17, path: '/shop', component: Shop},
    { id: 18, path: '/shop-single', component: ShopSingle},
    { id: 19, path: '/blog', component: Blog},
    { id: 20, path: '/blog-single', component: BlogSingle},
    { id: 21, path: '/contact-us', component: ContactUs},
    { id: 22, path: '/privacy-policy', component: Privacy},
    { id: 23, path: '/terms-and-conditions', component: Terms},
    { id: 24, path: '/service', component: CourseSingle},
    { id: 25, path: '/404', component: NotFound },
    { id: 26, path: '/sitemap', component: Sitemap},
]

export default routes;