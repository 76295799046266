import React from "react";
import { Redirect, Route, Switch } from 'react-router-dom'
import routes from './components/pages/index'

const NotFound = () => <Redirect to="/404" />;

function App() {
  return (
      <Switch>
        {
          routes.map((data, _) => (
            <Route exact path={data.path} component={data.component} key={data.id}></Route>
          ))
        }
        <Route path="*" component={NotFound} />
      </Switch>
     
  );
}

export default App;
