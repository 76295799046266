import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class TextbookSolution extends Component {
    render() {
        return (
            <div className="content-wrap courses-single-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content-page-wrap">
                                <div className="flat-tabs bg-fff border-ra4 border-f-e6f3ff">
                                    <ul className="tab-title style1 clearfix border-bt-e6f3ff">
                                        <li className="item-title  overview">
                                            <span className="inner">OVERVIEW</span>
                                        </li>
                                        <li className="item-title event">
                                            <span className="inner">DESCRIPTION</span>
                                        </li>
                                        <li className="item-title requirements">
                                            <span className="inner">DOWNLOAD SAMPLES</span>
                                        </li>
                                    </ul>
                                    <div className="tab-content-wrap">
                                        <div className="tab-content">
                                            <div className="item-content">
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="text-wrap img-right clearfix">
                                                            <h6 className="title">Key Issue</h6>
                                                            <p className="text">Textbook solutions are an important aspect of education in schools, universities, and other educational settings. Customers all throughout the world use them to augment classroom learning. Yet, not all publishers and content creators are equally qualified. As a result, collaborating with an unprofessional service provider frequently leads in quality and other concerns.</p>
                                                            <p className="text">For each field, most agencies have Subject Matter Experts (SMEs) and academic authors. They solely provide textbook solutions for a few basic courses. Nonetheless, some businesses exclusively give content in English. It is a disadvantage since clients have consumers all over the world in the global market. As a result, they must provide textbook solutions in several languages. Moreover, many agencies rarely provide validated answers, which raises the likelihood of disinformation.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="img-single-small flat-text-center">
                                                            <img src="/images/courses/textbook-bg-img.png" alt="nstructive" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 flex-align-center">
                                                        <div className="img-single-small flat-text-center">
                                                            <img src="images/courses/textbook-feature-img.jpg" alt="nstructive" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="text-wrap img-left clearfix">
                                                            <h6 className="title">Types of Textbook Solutions</h6>
                                                            <p className="text">Our tutors have extensive expertise teaching a variety of disciplines and courses. We have a large network of teachers all around the world. As a result, in collaboration with our content and technological teams, they offer a variety of tutorial services to fulfil the needs of clients globally.</p>
                                                            <ul className="list-skill">
                                                                <li> Grade Specific</li>
                                                                <li> Subject Specific</li>
                                                                <li> Board specific</li>
                                                                <li> Course Specific</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="text-wrap">
                                                            <h6 className="title">Overcoming Obstacles in Delivering High-Quality Textbook Solutions for Services Companies</h6>
                                                            <p className="text">One of the significant challenges is keeping up with the rapidly evolving nature of the services industry. As new technologies and practices emerge, service providers must adapt their textbook solutions to remain relevant and provide value to their clients. Failure to do so could lead to outdated and ineffective solutions, resulting in dissatisfied clients and lost business.</p>
                                                            <p className="text">Another challenge is tailoring textbook solutions to meet the specific needs and requirements of each client. Services companies operate in diverse fields, and each client has unique needs and challenges. As such, textbook solution providers must ensure that their solutions address each client's specific pain points and deliver tangible results.</p>
                                                            <p className="text">In conclusion, textbook solution services companies face several challenges in providing effective solutions to clients in the services industry. However, by staying up-to-date with industry trends, tailoring solutions to meet each client's unique needs, and providing the latest and most accurate information, these companies can overcome these obstacles and deliver high-quality solutions that help their clients succeed.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="wrap-checkmark-tag clearfix">
                                                            <div className="tags one-of-two">
                                                                <Link to="#" className="tag-item bg-7ffe53">Textbook Solution</Link>
                                                                <Link to="#" className="tag-item bg-fe5367">K12</Link>
                                                                <Link to="#" className="tag-item bg-536dfe">All Streams</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-content">
                                            <div className="item-content">
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="text-wrap">
                                                            <h6 className="title">How Nstructive Can Assit?</h6>
                                                            <p className="text">Textbook solutions are essential for students and professionals who want to excel in their fields. Among the companies that provide these services, Nstructive stands out as a top-quality provider. Nstructive offers comprehensive, up-to-date, and customized textbook solutions for a variety of industries, including healthcare, law, engineering, and more.</p>
                                                            <p className="text">One of the main advantages of Nstructive over other services companies is the level of customization they offer. Nstructive understands that each client has unique needs and challenges, so they work closely with them to create tailored textbook solutions that address their specific pain points. This approach ensures that clients receive solutions that are relevant, practical, and actionable.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="img-single-small flat-text-center">
                                                            <img src="images/courses/content-img.png" alt="nstructive" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="text-wrap">
                                                            <h6 className="title">Customer Obsession</h6>
                                                            <p className="text">Nstructive's commitment to customer service is second to none. Their team of experienced professionals is always available to answer clients' questions, provide support, and ensure that they are getting the most out of their textbook solutions. In conclusion, Nstructive is a top-quality provider of textbook solutions for a variety of industries. Their customized, up-to-date, and user-friendly approach sets them apart from other services companies. With Nstructive, clients can rest assured that they are receiving the best possible solutions for their needs.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="wrap-checkmark-tag clearfix">
                                                            <div className="tags one-of-two">
                                                                <Link to="#" className="tag-item bg-7ffe53">Textbook Solution</Link>
                                                                <Link to="#" className="tag-item bg-fe5367">K12</Link>
                                                                <Link to="#" className="tag-item bg-536dfe">All Streams</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-content">
                                            <div className="item-content">
                                                <div className="row">
                                                    <div className="col-md-12"></div>
                                                </div>
                                            </div>
                                            {/* <div className="wrap-checkmark-tag clearfix">
                                                <div className="tags one-of-two">
                                                    <Link to="#" className="tag-item bg-7ffe53">Textbook Solution</Link>
                                                    <Link to="#" className="tag-item bg-fe5367">K12</Link>
                                                    <Link to="#" className="tag-item bg-536dfe">All Streams</Link>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
}

export default TextbookSolution;