import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class CategoryComponent extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-6">
                    <Link to="/service?servicename=textbook-solution" onClick={() => { window.location.href = "/service?servicename=textbook-solution" }}>
                        <div className="category bg-282939">
                            <div className="icon">
                                <span className="icon-design">
                                    <span className="path1" /><span className="path2" /><span className="path3" /><span
                                        className="path4" /><span className="path5" /><span className="path6" /><span
                                        className="path7" /><span className="path8" /><span className="path9" /><span
                                        className="path10" /><span className="path11" /><span className="path12" /><span
                                        className="path13" /><span className="path14" /><span className="path15" /><span
                                        className="path16" /><span className="path17" /><span className="path18" /><span
                                        className="path19" /><span className="path20" /><span className="path21" /><span
                                        className="path22" /><span className="path23" /><span className="path24" /><span
                                        className="path25" /><span className="path26" /><span className="path27" /><span
                                        className="path28" /><span className="path29" />
                                </span>
                            </div>
                                <div className="content">
                                    <h6 className="title color-fff">TEXTBOOK SOLUTION</h6>
                                </div>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                    <Link to="/service?servicename=alt-text-writing" onClick={() => { window.location.href = "/service?servicename=alt-text-writing" }}>
                        <div className="category bg-1d3240">
                            <div className="icon">
                                <span className="icon-grapphic" />
                            </div>
                            <div className="content">
                                <h6 className="title color-fff">ALT TEXT WRITING</h6>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                    <Link to="/service?servicename=qa-service" onClick={() => { window.location.href = "/service?servicename=qa-service" }}>
                        <div className="category bg-282939">
                            <div className="icon">
                                <span className="icon-design">
                                    <span className="path1" /><span className="path2" /><span className="path3" /><span
                                        className="path4" /><span className="path5" /><span className="path6" /><span
                                        className="path7" /><span className="path8" /><span className="path9" /><span
                                        className="path10" /><span className="path11" /><span className="path12" /><span
                                        className="path13" /><span className="path14" /><span className="path15" /><span
                                        className="path16" /><span className="path17" /><span className="path18" /><span
                                        className="path19" /><span className="path20" /><span className="path21" /><span
                                        className="path22" /><span className="path23" /><span className="path24" /><span
                                        className="path25" /><span className="path26" /><span className="path27" /><span
                                        className="path28" /><span className="path29" />
                                </span>
                            </div>
                            <div className="content">
                                <h6 className="title color-fff">Q&A SERVICES</h6>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                    <Link to="/service?servicename=editorial" onClick={() => { window.location.href = "/service?servicename=editorial" }}>
                        <div className="category bg-1d3240">
                            <div className="icon">
                                <span className="icon-camera">
                                    <span className="path1" /><span className="path2" /><span className="path3" /><span
                                        className="path4" /><span className="path5" /><span className="path6" /><span
                                        className="path7" /><span className="path8" /><span className="path9" /><span
                                        className="path10" /><span className="path11" />
                                </span>
                            </div>
                            <div className="content">
                                <h6 className="title color-fff">EDITORIAL</h6>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                    <Link to="/service?servicename=assignment-help" onClick={() => { window.location.href = "/service?servicename=assignment-help" }}>
                        <div className="category bg-282939">
                            <div className="icon">
                                <span className="icon-progress">
                                    <span className="path1" /><span className="path2" /><span className="path3" /><span
                                        className="path4" /><span className="path5" /><span className="path6" /><span
                                        className="path7" /><span className="path8" /><span className="path9" /><span
                                        className="path10" /><span className="path11" /><span className="path12" /><span
                                        className="path13" /><span className="path14" /><span className="path15" /><span
                                        className="path16" />
                                </span>
                            </div>
                            <div className="content">
                                <h6 className="title color-fff">ASSIGNMENT HELP</h6>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                    <Link to="/service?servicename=online-tutoring" onClick={() => { window.location.href = "/service?servicename=online-tutoring" }}>
                        <div className="category bg-1d3240">
                            <div className="icon">
                                <span className="icon-idea">
                                    <span className="path1" /><span className="path2" /><span className="path3" /><span
                                        className="path4" /><span className="path5" /><span className="path6" /><span
                                        className="path7" /><span className="path8" /><span className="path9" /><span
                                        className="path10" /><span className="path11" /><span className="path12" /><span
                                        className="path13" /><span className="path14" /><span className="path15" /><span
                                        className="path16" /><span className="path17" />
                                </span>
                            </div>
                            <div className="content">
                                <h6 className="title color-fff">ONLINE TUTORING</h6>
                            </div>
                        </div>
                    </Link>
                </div>
                {/* <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="category bg-252a36">
                        <div className="icon">
                            <span className="icon-java">
                                <span className="path1" /><span className="path2" /><span className="path3" /><span
                                    className="path4" /><span className="path5" /><span className="path6" /><span
                                    className="path7" /><span className="path8" />
                            </span>
                        </div>
                        <div className="content">
                            <h6 className="title"><Link to="#">JAVA SCRIPT</Link></h6>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="category bg-1c3239">
                        <div className="icon">
                            <span className="icon-font">
                                <span className="path1" /><span className="path2" /><span className="path3" /><span
                                    className="path4" /><span className="path5" /><span className="path6" />
                            </span>
                        </div>
                        <div className="content">
                            <h6 className="title"><Link to="#">LANGUAGE</Link></h6>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="category bg-293542">
                        <div className="icon">
                            <span className="icon-app">
                                <span className="path1" /><span className="path2" /><span className="path3" /><span
                                    className="path4" /><span className="path5" /><span className="path6" /><span
                                    className="path7" /><span className="path8" /><span className="path9" /><span
                                    className="path10" /><span className="path11" /><span className="path12" /><span
                                    className="path13" /><span className="path14" /><span className="path15" /><span
                                    className="path16" /><span className="path17" /><span className="path18" /><span
                                    className="path19" /><span className="path20" /><span className="path21" />
                            </span>
                        </div>
                        <div className="content">
                            <h6 className="title"><Link to="#">APPS</Link></h6>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="category bg-1d2942">
                        <div className="icon">
                            <span className="icon-paper">
                                <span className="path1" /><span className="path2" /><span className="path3" /><span
                                    className="path4" /><span className="path5" /><span className="path6" /><span
                                    className="path7" /><span className="path8" /><span className="path9" />
                            </span>
                        </div>
                        <div className="content">
                            <h6 className="title"><Link to="#">BUSINESS</Link></h6>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="category bg-172e3f">
                        <div className="icon">
                            <span className="icon-layer">
                                <span className="path1" /><span className="path2" /><span className="path3" /><span
                                    className="path4" /><span className="path5" /><span className="path6" /><span
                                    className="path7" /><span className="path8" />
                            </span>
                        </div>
                        <div className="content">
                            <h6 className="title"><Link to="#">ILLUSTRATION</Link></h6>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="category bg-27313a">
                        <div className="icon">
                            <span className="icon-question">
                                <span className="path1" /><span className="path2" /><span className="path3" /><span
                                    className="path4" /><span className="path5" /><span className="path6" /><span
                                    className="path7" /><span className="path8" /><span className="path9" /><span
                                    className="path10" /><span className="path11" /><span className="path12" /><span
                                    className="path13" /><span className="path14" /><span className="path15" /><span
                                    className="path16" /><span className="path17" /><span className="path18" />
                            </span>
                        </div>
                        <div className="content">
                            <h6 className="title"><Link to="#">SUPPORT</Link></h6>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}

export default CategoryComponent;