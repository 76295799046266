import React, { Component } from 'react';
import { ContentRight, FeatureComponent, SideBarLeft, TabSlideComponent } from '../layouts/course03';
import {  Header, TopBar, Paramater, Footer, BottomBar , Loader} from '../layouts/general';
import { BannerTitle } from '../layouts/general/course';
class CourseV3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Courses'
                }
            ],
            titleBanner: [
                {
                    id: '1',
                    classnames: 'flat-title-page parallax parallax12 undescription',
                    classoverlay: 'overlay',
                    classbread: 'breadcrumbs',
                    titles:'Courses',
                    description: undefined,
                    name01: 'Home', 
                    name02: 'Courses',
                    link01: '/courses-grid-v3',
                    srcimg: 'images/title-page/bg-white.png',

                }
            ]
        }
    }
    render() {
        return (
            <div className="bg-body2">
                <div className="boxed">
                    <Loader />
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }
                    {
                        this.state.titleBanner.map(data => (
                            <BannerTitle data={data} key={data.id}/>
                        ))
                    }
                    <div className="content-wrap courses-grid-v3-page">
                        <TabSlideComponent />
                        <FeatureComponent />
                        <div className="content-page-fullwidth-wrap clearfix pd-top-45">
                            <div className="container-fluid ">
                                <SideBarLeft />
                                <ContentRight />
                            </div>
                        </div>
                    </div>
                    <section className="flat-feature parallax parallax2">
                        <div className="container">
                            <Paramater />
                        </div>
                    </section>
                    <Footer />
                    <BottomBar />
                </div>  
            </div>
        );
    }
}

export default CourseV3;