const dataShop = [
    {
        id: 1,
        srcimg: 'images/shop/product-1.jpg',
        title: 'Accessibility for Everyone',
        pricesale: '$19.99',
        price: '$99.99',
    },
    {
        id: 2,
        srcimg: 'images/shop/product-2.jpg',
        title: 'Just Enough Research',
        pricesale: '$26.99',
        price: '$54.99',
    },
    {
        id: 3,
        srcimg: 'images/shop/product-3.jpg',
        title: 'Color Accessibility Workflows',
        pricesale: '$58.99',
        price: undefined,
    },
    {
        id: 4,
        srcimg: 'images/shop/product-4.jpg',
        title: 'JavaScript for Web Designers',
        pricesale: '$13.99',
        price: '$49.99 ',
    },
    {
        id: 5,
        srcimg: 'images/shop/product-5.jpg',
        title: 'Going Offline',
        pricesale: '$23.99',
        price: undefined,
    },
    {
        id: 6,
        srcimg: 'images/shop/product-6.jpg',
        title: 'Practical SVG',
        pricesale: '$48.99',
        price: '$89.99 ',
    },
    {
        id: 7,
        srcimg: 'images/shop/product-7.jpg',
        title: 'Design for Real Life',
        pricesale: '$19.99',
        price: '$99.99 ',
    },
    {
        id: 8,
        srcimg: 'images/shop/product-8.jpg',
        title: 'Designing for Touch',
        pricesale: '$52.99',
        price: undefined,
    },
    {
        id: 9,
        srcimg: 'images/shop/product-9.jpg',
        title: 'Sass for Web Designers',
        pricesale: '$38.99',
        price: undefined,
    },
]

export default dataShop;