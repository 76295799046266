import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { CardEventComponent } from '../layouts/events';
import { Header, Footer, BottomBar, TopBar , Loader, Paramater} from '../layouts/general';
import { BannerTitle } from '../layouts/general/course';
class EventV1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Events'
                }
            ],
            titleBanner: [
                {
                    id: '1',
                    classnames: 'flat-title-page parallax parallax1',
                    classoverlay: 'overlay',
                    classbread: 'breadcrumbs',
                    titles:'Events',
                    description: 'All our courses are self-paced and have been designed by subject matter experts, to give you an interactive and enriched learning experience.',
                    name01: 'Home', 
                    name02: 'Events',
                    link01: '/event-v1',
                    srcimg: 'images/title-page/bg-white.png',

                }
            ]
        }
    }
    render() {
        return (
            <div className="bg-body">
                <div className="boxed">
                <Loader />
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }
                    {
                        this.state.titleBanner.map(data => (
                            <BannerTitle data={data} key={data.id}/>
                        ))
                    }
                    <div className="content-wrap event-v1-page">
                        <div className="container">
                            <div className="content-page-wrap">
                                <CardEventComponent />
                                <div className="flat-paginations">
                                    <ul className="list flat-text-center pagination-wrap">
                                        <li className="disabled"><Link to="#" className="btn-navs">PRE</Link></li>
                                        <li className="active"><Link to="#">1</Link></li>
                                        <li><Link to="#">2</Link></li>
                                        <li><Link to="#">3</Link></li>
                                        <li><Link to="#">4</Link></li>
                                        <li><Link to="#">5</Link></li>
                                        <li><Link to="#" className="btn-navs">NEXT</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="flat-feature parallax parallax2">
                        <div className="container">
                            <Paramater />
                        </div>
                    </section>
                    <Footer />
                    <BottomBar />
                </div> 
            </div>
        );
    }
}

export default EventV1;