import React, { Component } from 'react'
import {Link} from 'react-router-dom'
class EventBoxs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataEvents: [
                {
                    id: 1,
                    dataanimate: 'zoomIn',
                    delay: '0.2s',
                    offset: '75%',
                    srcimg: 'images/events/event-grid2-1.jpg',
                    title: 'TED Talks at UCF College of Education',
                    time: '08:00 - 11:00',
                    address: 'Melbourne',
                    day: '8',
                    month: 'JUNE',
                    classnames: 'meta-date flat-text-center bg-1dadff'
                },
                {
                    id: 2,
                    dataanimate: 'zoomIn',
                    delay: '0.4s',
                    offset: '75%',
                    srcimg: 'images/events/event-grid2-2.jpg',
                    title: 'Summer Course Starts From June',
                    time: '08:00 - 11:00',
                    address: 'Melbourne',
                    day: '10',
                    month: 'JUNE',
                    classnames: 'meta-date flat-text-center bg-3caecc'
                },
                {
                    id: 3,
                    dataanimate: 'zoomIn',
                    delay: '0.6s',
                    offset: '75%',
                    srcimg: 'images/events/event-grid2-3.jpg',
                    title: 'Importance of Research Seminar',
                    time: '08:00 - 11:00',
                    address: 'Melbourne',
                    day: '17',
                    month: 'JUNE',
                    classnames: 'meta-date flat-text-center'
                },
            ]
        }
    }
    
    render() {
        return (
            <div className="row">
                {
                    this.state.dataEvents.map(data => (
                        <div className="col-lg-4 col-md-12 effect-animation" data-animation={data.dataanimate} data-animation-delay={data.delay} data-animation-offset={data.offset} key={data.id}>
                            <div className="event style2">
                                <div className="event-img">
                                    <Link to="#"><img src={data.srcimg} alt="nstructive" /></Link>
                                </div>
                                <div className="event-content">
                                    <h6 className="title"><Link to="/event-single" onClick={() => {window.location.href="/event-single"}}>{data.title}</Link></h6>
                                    <div className="meta-list">
                                        <ul className="list">
                                            <li className="time"><Link to="#">{data.time}</Link></li>
                                            <li className="address"><Link to="#">{data.address}</Link></li>
                                        </ul>
                                    </div>
                                    <div className={data.classnames}>
                                        <p className="day">{data.day}</p>
                                        <p className="month">{data.month}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default EventBoxs;