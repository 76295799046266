import React, { Component } from 'react';
import { Header, Footer, BottomBar, TopBar , Loader, Paramater} from '../layouts/general';
import { BannerTitle } from '../layouts/general/course';
import {CardIntructor} from '../layouts/intructor';
class IntructorPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Instructors'
                }
            ],
            titleBanner: [
                {
                    id: '1',
                    classnames: 'flat-title-page parallax parallax1',
                    classoverlay: 'overlay style4',
                    classbread: 'breadcrumbs',
                    titles:'Instructors',
                    description: 'All our courses are self-paced and have been designed by subject matter experts, to give you an interactive and enriched learning experience.',
                    name01: 'Home', 
                    name02: 'Instructors',
                    link01: '/instructors',
                    srcimg: 'images/title-page/bg-white.png',

                }
            ]
        }
    }
    render() {
        return (
            <div className="bg-body">
                <div className="boxed">
                <Loader />
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }
                    {
                        this.state.titleBanner.map(data => (
                            <BannerTitle data={data} key={data.id}/>
                        ))
                    }
                    <div className="content-wrap  instructors-page">
                        <div className="container">
                            <div className="content-page-wrap">
                                <CardIntructor />
                            </div>
                        </div>
                    </div>
                    <section className="flat-feature parallax parallax2">
                        <div className="container">
                            <Paramater />
                        </div>
                    </section>
                    <Footer />
                    <BottomBar />
                </div> 
            </div>
        );
    }
}

export default IntructorPage;