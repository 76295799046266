const dataTab = [
    {
        id: 1,
        classnames: 'course style4 text-2ebd59 ',
        imgsrc: 'images/courses/courses-gird-v3-1.jpg',
        title: 'jQuery Mobile for Beginners',
        teacher: 'Ana Murphy',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: '$49.00',
        date: 'Sep 07, 2018',
        views: '1,547,932'
    },
    {
        id: 2,
        classnames: 'course style4 text-ea0042',
        imgsrc: 'images/courses/courses-gird-v3-2.jpg',
        title: 'Complete Python Bootcamp',
        teacher: 'Tom Steven',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: '$14.00',
        date: 'Sep 07, 2018',
        views: '1,547,932'
    },
    {
        id: 3,
        classnames: 'course style4 text-c100ea ',
        imgsrc: 'images/courses/courses-gird-v3-3.jpg',
        title: 'Complete Java Masterclass',
        teacher: 'Ana Murphy',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: 'FREE',
        date: 'Sep 07, 2018',
        views: '1,547,932'
    },
    {
        id: 4,
        classnames: 'course style4 text-256cff',
        imgsrc: 'images/courses/courses-gird-v3-4.jpg',
        title: 'Learning Python Data Analysis',
        teacher: 'Gilbert J. David',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: 'FREE',
        date: 'Sep 07, 2018',
        views: '1,547,932'
    },
    {
        id: 5,
        classnames: 'course style4 text-c100ea',
        imgsrc: 'images/courses/courses-gird-v3-5.jpg',
        title: 'Angular 6 - The Complete Guide',
        teacher: 'Charlotte H. Bronk',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: '$24.00',
        date: 'Sep 07, 2018',
        views: '1,547,932'
    },
    {
        id: 6,
        classnames: 'course style4 text-c100ea',
        imgsrc: 'images/courses/courses-gird-v3-6.jpg',
        title: 'Learn Python & Ethical Hacking',
        teacher: 'Erika P. Sera',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: 'FREE',
        date: 'Sep 07, 2018',
        views: '1,547,932'
    },
    {
        id: 7,
        classnames: 'course style4 text-ea0042',
        imgsrc: 'images/courses/courses-gird-v3-7.jpg',
        title: 'Node.js Developer Course',
        teacher: 'Michael R. Ryan',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: '$44.00',
        date: 'Sep 07, 2018',
        views: '1,547,932'
    }
]

export default dataTab;