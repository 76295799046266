import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class IntroducFT extends Component {
    render() {
        return (
            <div className="widget-about">
                <div id="logo-ft">
                    <Link to="/"><img src="images/logo/logo@2x.png" alt="nstructive" width={157} height={29}
                        data-retina="images/logo/logo@2x.png" data-width={157} data-height={29} /></Link>
                </div>
                <p className="description">We are a startup established in 2020, working as a service based company helping in e-tutoring, content development and homework. We also help companies around the world to manage and scale their academic operations, with a group of Online tutors/teachers and trainers. Our Global Delivery Centre runs 24x7 to cater needs of different time zones across Globe.</p>
            </div>
        );
    }
}

export default IntroducFT;