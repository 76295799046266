const dataTab = [
    {
        id: 1,
        classnames: 'course style2 one-of-three text-005eea Marketing Design',
        imgsrc: 'images/courses/courses-gird-v2-1.jpg',
        title: 'Programming Techniques',
        teacher: 'Ana Murphy',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: 'FREE'
    },
    {
        id: 2,
        classnames: 'course style2 one-of-three text-ea0042 Marketing ',
        imgsrc: 'images/courses/courses-gird-v2-2.jpg',
        title: 'Complete JavaScript Course',
        teacher: 'Rosy Janner',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '4.7  (2,632)',
        price: '$49.00'
    },
    {
        id: 3,
        classnames: 'course style2 one-of-three text-00dcea Marketing Design',
        imgsrc: 'images/courses/courses-gird-v2-3.jpg',
        title: 'Learning Python Data Analysis',
        teacher: 'Tom Steven',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '4.8  (7,982)',
        price: '$89.00'
    },
    {
        id: 4,
        classnames: 'course style2 one-of-three text-005eea Business',
        imgsrc: 'images/courses/courses-gird-v2-4.jpg',
        title: 'jQuery Mobile for Beginners',
        teacher: 'Ana Murphy',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: 'FREE'
    },
    {
        id: 5,
        classnames: 'course style2 one-of-three text-ea0042 Design',
        imgsrc: 'images/courses/courses-gird-v2-5.jpg',
        title: 'Web Technology',
        teacher: 'Rosy Janner',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '4.7  (2,632)',
        price: '$49.00'
    },
    {
        id: 6,
        classnames: 'course style2 one-of-three text-00dcea Leadership',
        imgsrc: 'images/courses/courses-gird-v2-6.jpg',
        title: 'Special Education Teaching',
        teacher: 'Rosy Janner',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '4.8  (7,982)',
        price: '$89.00'
    }
]

export default dataTab;