import React, { Component } from 'react';
import {Link,withRouter} from 'react-router-dom';
import dataV1 from './dataGridV1';
import dataV2 from './dataGridV2';
class TabGridV1 extends Component {
    render() {
        const {location} = this.props;
        const mainContent = () => {
            if(location.pathname === "/courses-grid-v1"){
                return(
                    <div className="flat-courses clearfix isotope-courses">                  
                    {
                        dataV1.map(data => (
                            <div className={data.classnames} key={data.id}>
                                <div className="course-border border-f-e6f3ff border-ra4 transition-vline">
                                    <div className="course-img img-vline">
                                        <Link to="#"><img src={data.imgsrc} alt="nstructive" /></Link>
                                        <div className="overlay">
                                            <span className="vline" />
                                            <span className="vline vline-bottom" />
                                        </div>
                                    </div>
                                    <div className="course-content">
                                        <div className="text-wrap border-bt-e6f3ff">
                                            <h6 className="title"><Link to="/courses-single" onClick={() => {window.location.href="/courses-single"}}>{data.title}</Link></h6>
                                            <p className="teacher"><Link to="#">{data.teacher}</Link></p>
                                            <p className="description">{data.description}</p>
                                        </div>
                                        <div className="wrap-rating-price">
                                            <div className="wrap-rating">
                                                <ul className="list star-list">
                                                    <li><i className="far fa-star" /></li>
                                                    <li><i className="far fa-star" /></li>
                                                    <li><i className="far fa-star" /></li>
                                                    <li><i className="far fa-star" /></li>
                                                    <li><i className="far fa-star" /></li>
                                                </ul>
                                                <span>{data.count}</span>
                                            </div>
                                            <span className="price">{data.price}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                )
            }else if(location.pathname === "/courses-grid-v2"){
                return(
                    <div className="flat-courses clear-sub-columns clearfix isotope-courses style2">
                        {
                            dataV2.map(data => (
                                <div className={data.classnames} key={data.id}>
                                    <div className="course-border border-f-e6f3ff border-ra4 transition-vline">
                                        <div className="course-img img-vline">
                                            <Link to="#"><img src={data.imgsrc} alt="nstructive" /></Link>
                                            <div className="overlay">
                                                <span className="vline" />
                                                <span className="vline vline-bottom" />
                                            </div>
                                        </div>
                                        <div className="course-content">
                                            <div className="text-wrap border-bt-e6f3ff">
                                                <h6 className="title"><Link to="/courses-single" onClick={() => {window.location.href="/courses-single"}}>{data.title}</Link></h6>
                                                <p className="teacher"><Link to="#">{data.teacher}</Link></p>
                                                <p className="description">{data.description}</p>
                                            </div>
                                            <div className="wrap-rating-price">
                                                <div className="wrap-rating">
                                                    <ul className="list star-list">
                                                        <li><i className="far fa-star" /></li>
                                                        <li><i className="far fa-star" /></li>
                                                        <li><i className="far fa-star" /></li>
                                                        <li><i className="far fa-star" /></li>
                                                        <li><i className="far fa-star" /></li>
                                                    </ul>
                                                    <span>{data.count}</span>
                                                </div>
                                                <span className="price">{data.price}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )
            }
        }
        return (
            <div className="content-page-wrap">
                <ul className="flat-filter-isotype style1 text-center ">
                    <li className="active"><Link to="#" data-filter="*">DEVELOPMENT</Link></li>
                    <li><Link to="#" data-filter=".Design">DESIGN </Link></li>
                    <li><Link to="#" data-filter=".Marketing"> MARKETING</Link></li>
                    <li><Link to="#" data-filter=".Photography"> PHOTOGRAPHY </Link></li>
                    <li><Link to="#" data-filter=".Leadership ">LEADERSHIP</Link></li>
                    <li><Link to="#" data-filter=".Business ">BUSINESS STUDIES</Link></li>
                </ul>
                { mainContent() }
            </div>
        );
    }
}

export default withRouter(TabGridV1);