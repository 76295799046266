import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Header, TopBar , Paramater, Footer, BottomBar, Loader } from '../layouts/general';
import {BannerComponent, InforComponent, TabAboutComponent } from '../layouts/pages';

class Faqs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'ABOUT US'
                }
            ],
            dataBanner: [
                {
                    id: 1,
                    classename01: 'flat-title-page parallax parallax7 style3',
                    classename02: 'container wrap-title-page flat-text-center',
                    links: '/about-us',
                    names: 'About Us'
                }
            ]
        }
    }
    render() {
        return (
            <div className="bg-body2">
                <div className="boxed">
                    <Loader />
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }
                    {
                        this.state.dataBanner.map(data => (
                            <BannerComponent data={data} key={data.id}/>
                        ))
                    }
                    <div className="content-wrap about-us-page">
                        <InforComponent />
                        <section className="key-success">
                            <div className="container">
                                <div className="row">
                                    <div className="tab-content-wrap">
                                        <div className="heading">
                                            <h6 className="title-small"> WHAT WE OFFER </h6>
                                            <h3 className="title">KEY SUCCESS FACTORS</h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="service-card">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <Link to="#"><img src="../images/about/offer-icon-01.png" alt="Service Images" /></Link>
                                                            <div className="shape-list">
                                                                <img className="shape shape-1" src="../images/about/service-icon-01.png" alt="Shape Images" />
                                                                <img className="shape shape-2" src="../images/about/service-icon-02.png" alt="Shape Images" />
                                                                <img className="shape shape-3" src="../images/about/service-icon-03.png" alt="Shape Images" />
                                                            </div>
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="title">Outcome Oriented</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="service-card">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <Link to="#"><img className="tech" src="../images/about/tech.png" alt="Service Images" /></Link>
                                                            <div className="shape-list">
                                                                <img className="shape shape-1" src="../images/about/service-icon-01.png" alt="Shape Images" />
                                                                <img className="shape shape-2" src="../images/about/service-icon-02.png" alt="Shape Images" />
                                                                <img className="shape shape-3" src="../images/about/service-icon-03.png" alt="Shape Images" />
                                                            </div>
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="title">Technology Savvy</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="service-card">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <Link to="#"><img src="../images/about/book.png" alt="Service Images" /></Link>
                                                            <div className="shape-list">
                                                                <img className="shape shape-1" src="../images/about/service-icon-01.png" alt="Shape Images" />
                                                                <img className="shape shape-2" src="../images/about/service-icon-02.png" alt="Shape Images" />
                                                                <img className="shape shape-3" src="../images/about/service-icon-03.png" alt="Shape Images" />
                                                            </div>
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="title">Cost Effective</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="service-card">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <Link to="#"><img src="../images/about/offer-icon-02.png" alt="Service Images" /></Link>
                                                            <div className="shape-list">
                                                                <img className="shape shape-1" src="../images/about/service-icon-01.png" alt="Shape Images" />
                                                                <img className="shape shape-2" src="../images/about/service-icon-02.png" alt="Shape Images" />
                                                                <img className="shape shape-3" src="../images/about/service-icon-03.png" alt="Shape Images" />
                                                            </div>
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="title">Customer Focused</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <TabAboutComponent />
                        {/* <VideoComponent /> */}
                        {/* <section className="flat-faqs">
                            <div className="heading flat-text-center">
                                <h6 className="title-small">QUESTIONS AND ANSWERS DISCUSSION (Q&amp;A)</h6>
                                <h3 className="title font-Poppins">Frequently Asked Questions (FAQ)</h3>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-1" />
                                    <div className="col-md-10">
                                        <LinkccordionCompent />
                                    </div>
                                </div>
                            </div>
                        </section> */}
                        {/* <section className="flat-free-month parallax parallax14">
                            <div className="container">
                                <BannerProfessional />
                            </div>
                        </section> */}
                    </div>
                    <section className="flat-feature parallax parallax2">
                        <div className="container">
                            <Paramater />
                        </div>
                    </section>
                    <Footer />
                    <BottomBar />
                </div>
            </div>
        );
    }
}

export default Faqs;