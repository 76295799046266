const data = [
    {
        id: 1,
        imgsrc: 'images/events/event-grid2-1.jpg',
        title: 'TED Talks at UCF College of Education',
        time: '08:00 - 11:00',
        address: 'Melbourne',
        day: '06',
        month: 'DECEMBER'
    },
    {
        id: 2,
        imgsrc: 'images/events/event-grid2-2.jpg',
        title: 'Summer Course Starts From June',
        time: '08:00 - 11:00',
        address: 'Melbourne',
        day: '09',
        month: 'DECEMBER'
    },
    {
        id: 3,
        imgsrc: 'images/events/event-grid2-3.jpg',
        title: 'Importance of Research Seminar',
        time: '08:00 - 11:00',
        address: 'Melbourne',
        day: '12',
        month: 'DECEMBER'
    },
    {
        id: 4,
        imgsrc: 'images/events/event-grid2-4.jpg',
        title: 'GLOW: An exhibition with The Greenway',
        time: '08:00 - 11:00',
        address: 'Melbourne',
        day: '15',
        month: 'DECEMBER'
    },
    {
        id:5,
        imgsrc: 'images/events/event-grid2-5.jpg',
        title: 'Kyle Cooper: The Art of the Title',
        time: '08:00 - 11:00',
        address: 'Melbourne',
        day: '16',
        month: 'DECEMBER'
    },
    {
        id: 6,
        imgsrc: 'images/events/event-grid2-6.jpg',
        title: 'Service Tuesday: Cradles  to Crayons school kits',
        time: '08:00 - 11:00',
        address: 'Melbourne',
        day: '18',
        month: 'DECEMBER'
    },
    {
        id: 7,
        imgsrc: 'images/events/event-grid2-7.jpg',
        title: 'Lesley 2030: Help shape our future',
        time: '08:00 - 11:00',
        address: 'Melbourne',
        day: '22',
        month: 'DECEMBER'
    },
    {
        id: 8,
        imgsrc: 'images/events/event-grid2-8.jpg',
        title: 'Boston Speakers Series: James Comey',
        time: '08:00 - 11:00',
        address: 'Melbourne',
        day: '24',
        month: 'DECEMBER'
    },
    {
        id: 9,
        imgsrc: 'images/events/event-grid2-9.jpg',
        title: 'Importance of Research Seminar',
        time: '08:00 - 11:00',
        address: 'Melbourne',
        day: '26',
        month: 'DECEMBER'
    }
]

export default data;