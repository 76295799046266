import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class Slider extends Component {
    render() {
        return (
            <div className="flat-slider style4">
                <div className="rev_slider_wrapper fullwidthbanner-container">
                    <div id="rev-slider4" className="rev_slider fullwidthabanner" data-version="5.4.8">
                        <ul>
                            <li data-transition="random">
                                <img src="images/slider/slider-4.jpg" alt="nstructive" className="rev-slidebg"
                                    data-bgposition="80 center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax={0} />
                                <div className="overlay" />
                                <div className="tp-caption tp-resizeme font-Poppins font-weight-700 color-192836"
                                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                    data-y="['middle','middle','middle','middle']" data-voffset="['-157','-187','-72','-72']"
                                    data-fontsize="['80','80','40','32']" data-lineheight="['100','100','45','35']"
                                    data-width="full" data-height="none" data-whitespace="normal" data-transform_idle="o:1;"
                                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                    data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                    data-mask_in="x:0px;y:[100%];" data-mask_out="x:inherit;y:inherit;" data-start={1000}
                                    data-splitin="none" data-splitout="none" data-textalign="['center','center','center','center']"
                                    data-responsive_offset="on">Learning <span className="color-ec5252"
                                        data-fontsize="['80','80','40','32']" data-lineheight="['100','100','45','35']">Courses
                                        Online</span></div>
                                <div className="tp-caption tp-resizeme   flat-text-center "
                                    data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                    data-y="['middle','middle','middle','middle']" data-voffset="['-51','-81','-27','27']"
                                    data-fontsize="['20','20','16','14']" data-lineheight="['32','32','22','20']" data-width="full"
                                    data-height="none" data-whitespace="normal" data-transform_idle="o:1;"
                                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                    data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                    data-mask_in="x:0px;y:[100%];" data-mask_out="x:inherit;y:inherit;" data-start={1000}
                                    data-splitin="none" data-splitout="none" data-responsive_offset="on"
                                    data-textalign="['center','center','center','center']" data-paddingleft="[100, 100, 0, 0]"
                                    data-paddingright="[100, 100, 0, 0]"> We offer professional SEO services that help websites
                                    <span className="color-515e6f font-size-20 font-italic text-underline"
                                        data-fontsize="['20','20','16','14']">increase their organic search</span> score drastically
                                    in order to compete for the highest rankings.</div>
                                <div className="tp-caption " data-x="['center','center','center','center']"
                                    data-hoffset="['0','0','0','0']" data-y="['middle','middle','middle','middle']"
                                    data-voffset="['78','48','60','100']" data-width="full" data-height="none"
                                    data-whitespace="normal" data-transform_idle="o:1;"
                                    data-transform_in="y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;s:2000;e:Power4.easeInOut;"
                                    data-transform_out="y:[100%];s:1000;e:Power2.easeInOut;s:1000;e:Power2.easeInOut;"
                                    data-mask_in="x:0px;y:[100%];" data-mask_out="x:inherit;y:inherit;" data-start={1000}
                                    data-splitin="none" data-splitout="none" data-responsive_offset="on"
                                    data-paddingtop="['50','50','50','50']" data-paddingbottom="['50','50','50','50']"
                                    data-textalign="['center','center','center','center']"><Link to="/courses-single"
                                        className="flat-button btn-start-slider border-ra4 v3"> START A COURSE</Link></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Slider;