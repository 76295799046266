import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import dataTab from './dataTabSlide'
class TabSlideComponent extends Component {
    render() {
        return (
            <section className="flat-popular-courses">
                <div className="flat-tabs  style3 ">
                    <ul className="tab-title clearfix style3 pd-left-15">
                        <li className="item-title  ">
                            <span className="inner">NEW RELEASES</span>
                        </li>
                        <li className="item-title ">
                            <span className="inner">ART &amp; DESIGN </span>
                        </li>
                        <li className="item-title ">
                            <span className="inner">BUSINESS </span>
                        </li>
                        <li className="item-title ">
                            <span className="inner">IT &amp; SOFTWARE</span>
                        </li>
                        <li className="item-title ">
                            <span className="inner">PORGRAMMING LANGUAGES </span>
                        </li>
                        <li className="item-title ">
                            <span className="inner">WEEKLY SERIES </span>
                        </li>
                    </ul>
                    <div className="tab-content-wrap">
                        {
                            dataTab.map( data => (
                                <div className="tab-content" key={data.id}>
                                    <div className="item-content">
                                        <div className="flat-carousel" data-column={4} data-column2={2} data-loop="false" data-column3={1}
                                            data-gap={0} data-dots="false" data-nav="true">
                                            <div className="flat-courses clearfix owl-carousel">
                                                {
                                                    data.content.map(datas => (
                                                        <div className={datas.classnames} key={datas.id}>
                                                            <div className="course-border border-f-e6f3ff border-ra4 transition-vline">
                                                                <div className="course-img img-vline">
                                                                    <Link to="#"><img src={datas.imgsrc} alt="nstructive" /></Link>
                                                                    <div className="overlay">
                                                                        <span className="vline" />
                                                                        <span className="vline vline-bottom" />
                                                                    </div>
                                                                </div>
                                                                <div className="course-content">
                                                                    <div className="text-wrap border-bt-e6f3ff">
                                                                        <h6 className="title"><Link to="/courses-single" onClick={() => {window.location.href="/courses-single"}}>{datas.title}</Link></h6>
                                                                        <p className="teacher"><Link to="#">{datas.teacher}</Link></p>
                                                                        <p className="description">{datas.description}</p>
                                                                    </div>
                                                                    <div className="wrap-rating-price">
                                                                        <div className="wrap-rating">
                                                                            <ul className="list star-list">
                                                                                <li><i className="far fa-star" /></li>
                                                                                <li><i className="far fa-star" /></li>
                                                                                <li><i className="far fa-star" /></li>
                                                                                <li><i className="far fa-star" /></li>
                                                                                <li><i className="far fa-star" /></li>
                                                                            </ul>
                                                                            <span>{datas.count}</span>
                                                                        </div>
                                                                        <span className="price">{datas.price}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                } 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="wrap-btn flat-text-center">
                    <Link to="#" className="flat-button btn-all-sourses font-Poppins font-weight-700 border-ra4">BROWSE ALL
                        COURSES</Link>
                </div>
            </section>
            );
            }
}

export default TabSlideComponent;