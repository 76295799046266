const couresBox = [
    {
        id: 1,
        classnames: 'course text-2ebd59 Leadership ',
        srcimg: 'images/courses/courses-index4-1.jpg',
        title: 'jQuery Mobile for Beginners',
        teacher: 'Ana Murphy',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star',
        ratting: '5.0 (980)',
        pricing: 'FREE'
    },
    {
        id: 2,
        classnames: 'course text-ec5252 Design',
        srcimg: 'images/courses/courses-index4-2.jpg',
        title: 'Programming Techniques',
        teacher: 'Rosy Janner',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star',
        ratting: '5.0  (980)',
        pricing: '$49.00'
    },
    {
        id: 3,
        classnames: 'course text-3b42ff Leadership',
        srcimg: 'images/courses/courses-index4-3.jpg',
        title: 'Complete JavaScript Course',
        teacher: 'Tom Steven',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star',
        ratting: '5.0  (980)',
        pricing: '$24.00'
    },
    {
        id: 4,
        classnames: 'course text-37adff Design',
        srcimg: 'images/courses/courses-index4-4.jpg',
        title: 'Learning Python Data Analysis',
        teacher: 'Mark Henrey',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star',
        ratting: '5.0 (980)',
        pricing: '$89.00'
    },
    {
        id: 5,
        classnames: 'course text-ea0042 Leadership Photography',
        srcimg: 'images/courses/courses-gird-v1-10.jpg',
        title: 'The Ultimate Drawing Course',
        teacher: 'Rosy Janner',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star',
        ratting: '5.0  (980)',
        pricing: '$49.00'
    },
    {
        id: 6,
        classnames: 'course text-c100ea Business Leadership Photography',
        srcimg: 'images/courses/courses-gird-v1-11.jpg',
        title: 'Learn Python & Ethical Hacking',
        teacher: 'Tom Steven',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star',
        ratting: '5.0  (980)',
        pricing: '$24.00'
    },
    {
        id: 7,
        classnames: 'course text-256cff Photography',
        srcimg: 'images/courses/courses-gird-v1-12.jpg',
        title: 'Advanced CSS Flexbox',
        teacher: 'Mark Henrey',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star-half',
        ratting: '5.0  (980)',
        pricing: 'FREE'
    }
]

export default couresBox;