const intructors = [
    {
        id: 1,
        classnames: 'team-member border-ra4',
        src: 'images/instructors/instructor-1.jpg',
        name: 'Robert K. Johanson',
        position: 'Teacher',
        description: 'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        classicon: 'socails flat-text-center'
    },
    {
        id: 2,
        classnames: 'team-member border-ra4',
        src: 'images/instructors/instructor-2.jpg',
        name: 'Anna Kunytska',
        position: 'Teacher',
        description: 'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        classicon: 'socails flat-text-center'
    },
    {
        id: 3,
        classnames: 'team-member border-ra4',
        src: 'images/instructors/instructor-3.jpg',
        name: 'Ben Daniel',
        position: 'Teacher',
        description: 'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        classicon: 'socails flat-text-center'
    },
    {
        id: 4,
        classnames: 'team-member border-ra4',
        src: 'images/instructors/instructor-4.jpg',
        name: 'Eric L. Shipman',
        position: 'Teacher',
        description: 'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        classicon: 'socails flat-text-center'
    },
    {
        id: 5,
        classnames: 'team-member border-ra4',
        src: 'images/instructors/instructor-5.jpg',
        name: 'Kimberley D. Castro',
        position: 'Teacher',
        description: 'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        classicon: 'socails flat-text-center'
    },
    {
        id: 6,
        classnames: 'team-member border-ra4',
        src: 'images/instructors/instructor-6.jpg',
        name: 'Chris C. Wallace',
        position: 'Teacher',
        description: 'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        classicon: 'socails flat-text-center'
    },
    {
        id: 7,
        classnames: 'team-member border-ra4',
        src: 'images/instructors/instructor-7.jpg',
        name: 'Kelly W. Frazier',
        position: 'Teacher',
        description: 'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        classicon: 'socails flat-text-center'
    },
    {
        id: 8,
        classnames: 'team-member border-ra4',
        src: 'images/instructors/instructor-8.jpg',
        name: 'Harry J. Bryant',
        position: 'Teacher',
        description: 'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        classicon: 'socails flat-text-center'
    },
    {
        id: 9,
        classnames: 'team-member border-ra4',
        src: 'images/instructors/instructor-9.jpg',
        name: 'Lester M. Brunetti',
        position: 'Teacher',
        description: 'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        classicon: 'socails flat-text-center'
    }

]

export default intructors;