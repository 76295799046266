import React, { Component } from 'react';

class TabCoaching extends Component {
    render() {
        return (
            <div className="flat-tabs ">
                <ul className="tab-title clear-sub-columns style5">
                    <li className="item-title one-of-four">
                        <div className="iconbox style4 flat-text-center one">
                            <div className="iconbox-icon">
                                <span className="icon-portfolio-1">
                                    <span className="path1" /><span className="path2" /><span className="path3" /><span
                                        className="path4" /><span className="path5" /><span className="path6" /><span
                                        className="path7" /><span className="path8" /><span className="path9" /><span
                                        className="path10" /><span className="path11" /><span className="path12" /><span
                                        className="path13" /><span className="path14" /><span className="path15" /><span
                                        className="path16" /><span className="path17" />
                                </span>
                            </div>
                            <div className="iconbox-content">
                                <h6 className="title">
                                    Student Outcomes Driven Methodology
                                </h6>
                            </div>
                        </div>
                    </li>
                    <li className="item-title one-of-four">
                        <div className="iconbox style4 flat-text-center two">
                            <div className="iconbox-icon">
                                <span className="icon-portfolio-3">
                                    <span className="path1" /><span className="path2" /><span className="path3" /><span
                                        className="path4" /><span className="path5" /><span className="path6" /><span
                                        className="path7" /><span className="path8" /><span className="path9" /><span
                                        className="path10" /><span className="path11" /><span className="path12" /><span
                                        className="path13" /><span className="path14" /><span className="path15" /><span
                                        className="path16" /><span className="path17" />
                                </span>
                            </div>
                            <div className="iconbox-content">
                                <h6 className="title">
                                    Repurposing and Re-engineering Content 
                                </h6>
                            </div>
                        </div>
                    </li>
                    <li className="item-title one-of-four">
                        <div className="iconbox style4 flat-text-center three">
                            <div className="iconbox-icon">
                                <span className="icon-curriculum">
                                    <span className="path1" /><span className="path2" /><span className="path3" /><span
                                        className="path4" /><span className="path5" /><span className="path6" /><span
                                        className="path7" /><span className="path8" /><span className="path9" /><span
                                        className="path10" /><span className="path11" /><span className="path12" /><span
                                        className="path13" />
                                </span>
                            </div>
                            <div className="iconbox-content">
                                <h6 className="title">
                                    Teachers as Subject Matter Experts
                                </h6>
                            </div>
                        </div>
                    </li>
                    <li className="item-title one-of-four">
                        <div className="iconbox style4 flat-text-center four">
                            <div className="iconbox-icon">
                                <span className="icon-contract-1"><span className="path1" /><span className="path2" /><span
                                        className="path3" /><span className="path4" /><span className="path5" /><span
                                        className="path6" /><span className="path7" /><span className="path8" /><span
                                        className="path9" /><span className="path10" /><span className="path11" /><span
                                        className="path12" /><span className="path13" /><span className="path14" /></span>
                            </div>
                            <div className="iconbox-content">
                                <h6 className="title">
                                    Advanced Software Enabled Content
                                </h6>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className="tab-content-wrap">
                    <div className="tab-content box-shadow ">
                        <div className="item-content clearfix">
                            <div className="img flat-text-center">
                                <img src="images/feature/img-tab.png" alt="nstructive" />
                            </div>
                            <div className="text-wrap img-right clearfix">
                                <h4 className="title">
                                    Student Outcomes Driven Methodology
                                </h4>
                                <p className="text">
                                    As per Wikipedia, “Outcome-based education (OBE)  is an educational theory that bases each part of an educational system around goals(outcomes). By the end of educational experience, each student should have achieved the goal. There is no single specified style of teaching or assessment in OBE; instead, classes, opportunities and assessments should all help students achieve the specified outcomes. The role of faculty adapts into instructor, trainer, facilitator and/or mentor based on the outcomes targeted”.<br />
                                    Our methodology has produced results. 78 percent of students who use Nstructive's content and learning methods report an improvement in their academic performance.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content box-shadow ">
                        <div className="item-content clearfix">
                            <div className="img flat-text-center">
                                <img src="images/feature/img-tab.png" alt="nstructive" />
                            </div>
                            <div className="text-wrap img-right clearfix">
                                <h4 className="title">
                                    Repurposing and Re-engineering Content 
                                </h4>
                                <p className="text">
                                    Nstructive's content repurposing service enables businesses of all sizes to successfully exploit their intellectual capital by making their material easily accessible to a varied audience in a variety of learning scenarios and situations. Newer venues for material dissemination are the rule rather than the exception in an ever-changing technology-driven environment. Clients save time and money by utilising our team, and they have a single vendor to distribute their material to many distribution platforms.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content box-shadow ">
                        <div className="item-content clearfix">
                            <div className="img flat-text-center">
                                <img src="images/feature/img-tab.png" alt="nstructive" />
                            </div>
                            <div className="text-wrap img-right clearfix">
                                <h4 className="title">
                                    Teachers as Subject Matter Experts
                                </h4>
                                <p className="text">
                                    Nstructive has a 'Learning focused' DNA, which means that our 100+ full-time subject matter experts have a teaching background and develop content that motivates learners.<br />
                                    Nothing in education is ever easy. Teachers, whether subject experts or generalists, require a diverse set of abilities and attitudes in order to help their students reach high levels of success. These should include connections with students, subject matter expertise, and a grasp of pedagogical procedures in order to acquire the necessary understanding.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="tab-content box-shadow ">
                        <div className="item-content clearfix">
                            <div className="img flat-text-center">
                                <img src="images/feature/img-tab.png" alt="nstructive" />
                            </div>
                            <div className="text-wrap img-right clearfix">
                                <h4 className="title">
                                    Advanced Software Enabled Content
                                </h4>
                                <p className="text">
                                    We produce rich material, frequently integrated with multimedia, by utilising cutting-edge software tools such as MATLAB, Maple, STATISTICA, CorelDraw, and Adobe InDesign.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TabCoaching;