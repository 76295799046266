import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import menus from '../menu'
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            linklogo: '/'
        }
    }
    
    render() {
        const { location } = this.props;
        return (
            <div id="header" className="bg-fff style1">
                <div className="container">
                    <div className="header-wrap clearfix">
                        <div id="logo">
                            <Link to="/" onClick={() => {window.location.href=this.state.linklogo}}><img src="images/logo/logo@2x.png" alt="nstructive" width={157} height={29}
                                    data-retina="images/logo/logo@2x.png" data-width={157} data-height={29} /></Link>
                        </div>
                        <div className="nav-wrap flat-text-right style1">
                            <nav id="main-nav">
                                <ul className="menu">
                                    {
                                        menus.map(data =>(
                                            <li className={data.name === this.props.data.names ? "active" : ""} key={data.id}>
                                                <Link to={data.linkmenu} onClick={() => {window.location.href=data.linkmenu}}>{data.name}</Link>
                                                {
                                                    data.namesub === undefined ? "" : 
                                                    <ul className="submenu">
                                                        {
                                                            data.namesub.map(submenus =>(
                                                                <li className={location.pathname === submenus.links || submenus.sub.toLocaleLowerCase() === this.props.data.names02?.toLocaleLowerCase() ? "active" : ""} key={submenus.id}><Link to={submenus.links} onClick={() => {window.location.href=submenus.links}}>{submenus.sub}</Link>
                                                                    {
                                                                        submenus.submenu === undefined ? "" :
                                                                        <ul className="submenu">
                                                                            {
                                                                                submenus.submenu.map(menusub => (
                                                                                    <li className={location.pathname === menusub.linksub ? "active" : ""} key={menusub.id}><Link to= {menusub.linksub} onClick={() => {window.location.href=menusub.linksub}}>{menusub.titlesub}</Link></li>
                                                                                ))
                                                                            }
                                                                        </ul>
                                                                    }
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                }
                                            </li>
                                        ))
                                    }
                                </ul>
                            </nav>
                        </div>
                        <div className="mobile-button">
                            <span />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Header);