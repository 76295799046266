import React, { Component } from 'react';
import { Header, TopBar, Footer, BottomBar, Paramater, Loader} from '../layouts/general';
import { BannerTitle } from '../layouts/general/course';
import services from './services'

class CourseSingle extends Component {
    constructor(props) {
        super(props);
        const queryParams = new URLSearchParams(this.props.location.search);
        const servicename = queryParams.get('servicename');
        const allServices =
            ["textbook-solution", "alt-text-writing", "qa-service", "editorial", "assignment-help", "online-tutoring"];
        if (!allServices.includes(servicename)) {
            this.props.history.push('/');
        }
        const service = services[servicename.replace(/-/g, '')];
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'SERVICES',
                    names02: service.name,
                }
            ],
            titleBanner: [
                {
                    id: '1',
                    classnames: 'flat-title-page parallax parallax11 style4',
                    classoverlay: 'overlay style4',
                    classbread: 'breadcrumbs',
                    titles: service.name,
                    description: service.description,
                    name01: 'Home', 
                    name02: service.name,
                    link01: '/',
                    srcimg: 'images/title-page/bg-white.png',
                }
            ],
            service
        }
    }

    render() {
        return (
            <div className="bg-body">
                <div className="boxed">
                <Loader />
                <TopBar />
                {
                    this.state.headers.map(data => (
                        <Header data={data} key={data.id}/>
                    ))
                }
                {
                    this.state.titleBanner.map(data => (
                        <BannerTitle data={data} key={data.id}/>
                    ))
                }
                {
                    this.state.service.component
                }
                <section className="flat-feature parallax parallax2">
                    <div className="container">
                        <Paramater />
                    </div>
                </section>
                <Footer />
                    <BottomBar />
                </div> 
            </div>
        );
    }
}

export default CourseSingle;