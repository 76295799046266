const couresBox = [
    {
        id: 1,
        classnames: 'course style2 one-of-three text-005eea ',
        srcimg: 'images/courses/courses-gird-v2-1.jpg',
        title: 'Programming Techniques',
        teacher: 'Ana Murphy',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star',
        ratting: '5.0 (980)',
        pricing: 'FREE'
    },
    {
        id: 2,
        classnames: 'course style2 one-of-three text-1cd566',
        srcimg: 'images/courses/courses-gird-v2-2.jpg',
        title: 'Complete JavaScript Course',
        teacher: 'Rosy Janner',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star-half',
        ratting: '4.7 (2,632)',
        pricing: '$49.00'
    },
    {
        id: 3,
        classnames: 'course style2 one-of-three text-00dcea',
        srcimg: 'images/courses/courses-gird-v2-3.jpg',
        title: 'Learning Python Data Analysis',
        teacher: 'Tom Steven',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star-half',
        ratting: '4.8 (7,982)',
        pricing: '$24.00'
    },
    {
        id: 4,
        classnames: 'course style2 one-of-three text-005eea',
        srcimg: 'images/courses/courses-gird-v2-4.jpg',
        title: 'jQuery Mobile for Beginners',
        teacher: 'Ana Murphy',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star',
        ratting: '5.0 (980)',
        pricing: 'FREE'
    },
    {
        id: 5,
        classnames: 'course style2 one-of-three text-ea0042',
        srcimg: 'images/courses/courses-gird-v2-5.jpg',
        title: 'Web Technology',
        teacher: 'Rosy Janner',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star-half',
        ratting: '4.7 (2,632)',
        pricing: '$49.00'
    },
    {
        id: 6,
        classnames: 'course style2 one-of-three text-00dcea',
        srcimg: 'images/courses/courses-gird-v2-6.jpg',
        title: 'Learning Python Data Analysis',
        teacher: 'Rosy Janner',
        description:'Lobortis arcu, a vestibulum augue. Vivamus ipsum neque, facilisis vel mollis vitae.',
        icon01: 'far fa-star',
        icon02: 'far fa-star',
        icon03: 'far fa-star',
        icon04: 'far fa-star',
        icon05: 'far fa-star-half',
        ratting: '4.8 (7,982)',
        pricing: '$89.00'
    }
]

export default couresBox;