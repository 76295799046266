import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import dataAcodion from './dataAcodion'
class AcordionCompent extends Component {
    render() {
        return (  
            <div className="accordion">
                {
                dataAcodion.map(data =>(
                <div className="accordion-toggle border-f-e6f3ff border-ra4" key={data.id}>
                    <div className={data.classnames}>
                        {data.title}
                    </div>
                    <div className="toggle-content">
                        <p>{data.text}</p>
                        <h6 className="title font-Publico font-weight-700"><Link to="#">{data.textlinks} </Link> </h6>
                        <p>{data.description}</p>
                        <div className="clearfix" />
                    </div>
                </div>
                ))
                }
            </div>
        );
    }
}

export default AcordionCompent;