import React, { Component } from 'react';
import { UsefulLink, IntroducFT, RecentImage } from './footers/index';

class Footer extends Component {
    render() {
        return (
            <footer id="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <IntroducFT />
                        </div>
                        <div className="col-md-4">
                            <UsefulLink />
                        </div>
                        <div className="col-md-4">
                            <RecentImage />
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;