import React, { Component } from 'react';
import {Link} from 'react-router-dom'

class TopBar extends Component {
    render() {
        return (
            <div className="top border-bt-d1d1ff ">
                <div className="overlay v2" />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="flat-info style2 v2">
                                <ul>
                                    <li className="phone"><Link to="#">+123 345 678 000</Link></li>
                                    <li className="mail"><Link to="#">info@example.com</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 pd-left-0">
                            <div className="socails flat-text-right v2">
                                <ul className="list">
                                    <li><Link to="#"><span className="fa fa-twitter" /></Link></li>
                                    <li><Link to="#"><span className="fa fa-linkedin-square" /></Link></li>
                                    <li><Link to="#"><span className="fa fa-facebook-official" /></Link></li>
                                    <li><Link to="#"><span className="fa fa-skype" /></Link></li>
                                    <li><Link to="#"><span className="fa fa-pinterest-square" /></Link></li>
                                </ul>
                            </div>
                            <div className="flat-action flat-text-right style2 v2">
                                <ul>
                                    <li className="register"><Link to="#">REGISTER</Link></li>
                                    <li className="user"><Link to="#">LOGIN</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TopBar;