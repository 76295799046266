import React, { Component } from 'react';
import { TopBar, Header, TitleHeading,SlideTestimonial, Instructor, Paramater, Footer,BottomBar, Loader } from '../layouts/general';
import { CoursesBox, Slider, TabCoaching, UpcomingEvent, VideoComponent } from '../layouts/home02';

class Home02 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'Home'
                }
            ],
            titleCoaching: [
                {
                    id: 1,
                    title: 'Online Coaching',
                    classTitle: 'title',
                    classDescription: 'description',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                }
            ],
            titleCourses: [
                {
                    id: 1,
                    title: 'Discover Our Popular Courses',
                    classTitle: 'title',
                    classDescription: 'description',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                }
            ],
            titleEvents: [
                {
                    id: 1,
                    title: 'Upcoming Events',
                    classTitle: 'title color-fff',
                    classDescription: 'description color-fff',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                }
            ],
            titleTestimonial: [
                {
                    id: 1,
                    classTitle: 'title',
                    classDescription: 'description',
                    title: 'What Our Teacher’s Say',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                }
            ],
            titleIntructor: [
                {
                    id: 1,
                    classTitle: 'title',
                    classDescription: 'description',
                    title: 'Our Skilled Instructors',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                }
            ],
        }
    }
    
    render() {
        return (
            <div className="bg-body">
                <div className="boxed">
                    <Loader />
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }
                    <Slider />
                    <section className="flat-services">
                        <div className="container">
                            {
                                this.state.titleCoaching.map(data =>(
                                    <TitleHeading data={data} key={data.id}/>
                                ))
                            }
                            <TabCoaching />
                        </div>
                    </section>
                    <section className="flat-popular-courses style2">
                        <div className="container">
                            {
                                this.state.titleCourses.map(data =>(
                                    <TitleHeading data={data} key={data.id}/>
                                ))
                            }
                            <CoursesBox />
                        </div>
                    </section>
                    <section className="flat-upcoming-events style2">
                        <div className="overlay"></div>
                        <div className="container">
                            {
                                this.state.titleEvents.map(data =>(
                                    <TitleHeading data={data} key={data.id}/>
                                ))
                            }
                            <UpcomingEvent />
                        </div>
                    </section>
                    <section className="flat-testimonials style2 v2">
                        <div className="container">
                            {
                                this.state.titleTestimonial.map(data =>(
                                    <TitleHeading data={data} key={data.id}/>
                                ))
                            }
                            <SlideTestimonial />
                        </div>
                    </section>
                    <VideoComponent />
                    <section className="flat-skilled-instructors style2">
                        <div className="container">
                            {
                                this.state.titleIntructor.map(data =>(
                                    <TitleHeading data={data} key={data.id}/>
                                ))
                            }
                            <Instructor />
                        </div>
                    </section>
                    <section className="flat-feature parallax parallax2">
                        <div className="container">
                            <Paramater />
                        </div>
                    </section>
                    <Footer />
                    <BottomBar />
                </div>
            </div>
        );
    }
}

export default Home02;