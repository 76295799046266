import React, { Component } from 'react';
import { Link } from 'react-router-dom'
class SidebarBlog extends Component {
    render() {
        return (
            <div className="col-lg-4 col-md-12">
                <div className="sidebar-right">
                    <div className="widget-search">
                        <div className="flat-search v2">
                            <div className="form">
                                <input type="search" name="key" placeholder="Search" required />
                                <button className="btn-search flat-button"><i className="fas fa-search" /></button>
                            </div>
                        </div>
                    </div>
                    <div className="widget-categories v2 bg-fff border-f-e6f3ff">
                        <h6 className="title-widget"> Categories </h6>
                        <ul>
                            <li className="academics"><Link to="#">Academics</Link></li>
                            <li className="adverisement"><Link to="#">Adverisement</Link></li>
                            <li className="business"><Link to="#">Business</Link></li>
                            <li className="campus"><Link to="#">Campus Life</Link></li>
                            <li className="programming"><Link to="#">Programming Languages</Link></li>
                        </ul>
                    </div>
                    <div className="widget-related-sourses">
                        <h6 className="title-widget"> Related Courses </h6>
                        <div className="flat-courses">
                            <div className="course transition-vline clear-fix">
                                <div className="course-img img-vline">
                                    <Link to="#"><img src="images/courses/related-courses-1.jpg" alt="nstructive" /></Link>
                                    <div className="overlay">
                                        <span className="vline" />
                                        <span className="vline vline-bottom" />
                                    </div>
                                </div>
                                <div className="course-content">
                                    <h6 className="title"><Link to="/courses-single" onClick={() => {window.location.href="/courses-single"}}>Complete Java Masterclass</Link></h6>
                                    <p className="description">Lorem Ipsum is simply dummy text of the printing and typesetting.</p>
                                    <div className="list-meta">
                                        <div className="list-meta">
                                            <ul className="list">
                                                <li className="time">20.10.2019</li>
                                                <li className="folder">Education</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="course transition-vline clear-fix">
                                <div className="course-img img-vline">
                                    <Link to="#"><img src="images/courses/related-courses-2.jpg" alt="nstructive" /></Link>
                                    <div className="overlay">
                                        <span className="vline" />
                                        <span className="vline vline-bottom" />
                                    </div>
                                </div>
                                <div className="course-content">
                                    <h6 className="title"><Link to="/courses-single" onClick={() => {window.location.href="/courses-single"}}>Summer Course Starts</Link></h6>
                                    <p className="description">Lorem Ipsum is simply dummy text of the printing and typesetting.</p>
                                    <div className="list-meta">
                                        <div className="list-meta">
                                            <ul className="list">
                                                <li className="time">20.10.2019</li>
                                                <li className="folder">Education</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="course transition-vline clear-fix">
                                <div className="course-img img-vline">
                                    <Link to="#"><img src="images/courses/related-courses-3.jpg" alt="nstructive" /></Link>
                                    <div className="overlay">
                                        <span className="vline" />
                                        <span className="vline vline-bottom" />
                                    </div>
                                </div>
                                <div className="course-content">
                                    <h6 className="title"><Link to="/courses-single" onClick={() => {window.location.href="/courses-single"}}>New Exam Schedules</Link></h6>
                                    <p className="description">Lorem Ipsum is simply dummy text of the printing and typesetting.</p>
                                    <div className="list-meta">
                                        <div className="list-meta">
                                            <ul className="list">
                                                <li className="time">20.10.2019</li>
                                                <li className="folder">Education</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="widget-recent-work widget-sidebar">
                        <h6 className="widget-title">Instagram</h6>
                        <div className="flat-recentOwl" data-column={4} data-column2={3} data-loop="true" data-column3={2}
                            data-gap={0} data-dots="false" data-nav="true">
                            <div className="flat-imgbox style1 clearfix owl-carousel">
                                <div className="column">
                                    <div className="imgbox style1 transition-vline">
                                        <Link to="#">
                                            <div className="imgbox-img img-vline">
                                                <img src="images/footer/work-1.jpg" alt="nstructive" />
                                                <div className="overlay">
                                                    <span className="vline" />
                                                    <span className="vline vline-bottom" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="imgbox style1 transition-vline">
                                        <Link to="#">
                                            <div className="imgbox-img img-vline">
                                                <img src="images/footer/work-5.jpg" alt="nstructive" />
                                                <div className="overlay">
                                                    <span className="vline" />
                                                    <span className="vline vline-bottom" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="imgbox style1 transition-vline">
                                        <Link to="#">
                                            <div className="imgbox-img img-vline">
                                                <img src="images/footer/work-2.jpg" alt="nstructive" />
                                                <div className="overlay">
                                                    <span className="vline" />
                                                    <span className="vline vline-bottom" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="imgbox style1 transition-vline">
                                        <Link to="#">
                                            <div className="imgbox-img img-vline">
                                                <img src="images/footer/work-6.jpg" alt="nstructive" />
                                                <div className="overlay">
                                                    <span className="vline" />
                                                    <span className="vline vline-bottom" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="imgbox style1 transition-vline">
                                        <Link to="#">
                                            <div className="imgbox-img img-vline">
                                                <img src="images/footer/work-3.jpg" alt="nstructive" />
                                                <div className="overlay">
                                                    <span className="vline" />
                                                    <span className="vline vline-bottom" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="imgbox style1 transition-vline">
                                        <Link to="#">
                                            <div className="imgbox-img img-vline">
                                                <img src="images/footer/work-7.jpg" alt="nstructive" />
                                                <div className="overlay">
                                                    <span className="vline" />
                                                    <span className="vline vline-bottom" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="imgbox style1 transition-vline">
                                        <Link to="#">
                                            <div className="imgbox-img img-vline">
                                                <img src="images/footer/work-4.jpg" alt="nstructive" />
                                                <div className="overlay">
                                                    <span className="vline" />
                                                    <span className="vline vline-bottom" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="imgbox style1 transition-vline">
                                        <Link to="#">
                                            <div className="imgbox-img img-vline">
                                                <img src="images/footer/work-8.jpg" alt="nstructive" />
                                                <div className="overlay">
                                                    <span className="vline" />
                                                    <span className="vline vline-bottom" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="imgbox style1 transition-vline">
                                        <Link to="#">
                                            <div className="imgbox-img img-vline">
                                                <img src="images/footer/work-1.jpg" alt="nstructive" />
                                                <div className="overlay">
                                                    <span className="vline" />
                                                    <span className="vline vline-bottom" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="imgbox style1 transition-vline">
                                        <Link to="#">
                                            <div className="imgbox-img img-vline">
                                                <img src="images/footer/work-2.jpg" alt="nstructive" />
                                                <div className="overlay">
                                                    <span className="vline" />
                                                    <span className="vline vline-bottom" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="imgbox style1 transition-vline">
                                        <Link to="#">
                                            <div className="imgbox-img img-vline">
                                                <img src="images/footer/work-3.jpg" alt="nstructive" />
                                                <div className="overlay">
                                                    <span className="vline" />
                                                    <span className="vline vline-bottom" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="imgbox style1 transition-vline">
                                        <Link to="#">
                                            <div className="imgbox-img img-vline">
                                                <img src="images/footer/work-4.jpg" alt="nstructive" />
                                                <div className="overlay">
                                                    <span className="vline" />
                                                    <span className="vline vline-bottom" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="imgbox style1 transition-vline">
                                        <Link to="#">
                                            <div className="imgbox-img img-vline">
                                                <img src="images/footer/work-5.jpg" alt="nstructive" />
                                                <div className="overlay">
                                                    <span className="vline" />
                                                    <span className="vline vline-bottom" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="imgbox style1 transition-vline">
                                        <Link to="#">
                                            <div className="imgbox-img img-vline">
                                                <img src="images/footer/work-6.jpg" alt="nstructive" />
                                                <div className="overlay">
                                                    <span className="vline" />
                                                    <span className="vline vline-bottom" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="imgbox style1 transition-vline">
                                        <Link to="#">
                                            <div className="imgbox-img img-vline">
                                                <img src="images/footer/work-7.jpg" alt="nstructive" />
                                                <div className="overlay">
                                                    <span className="vline" />
                                                    <span className="vline vline-bottom" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="imgbox style1 transition-vline">
                                        <Link to="#">
                                            <div className="imgbox-img img-vline">
                                                <img src="images/footer/work-8.jpg" alt="nstructive" />
                                                <div className="overlay">
                                                    <span className="vline" />
                                                    <span className="vline vline-bottom" />
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wrap-btn">
                            <Link to="#" className="btn-view-more">VIEW MORE</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SidebarBlog;