const dataAccordion = [
    {
        id: 1,
        classnames: 'toggle-title active',
        title: 'What services do Nstructive offer',
        text: 'Nstructive Global Services provides a wide range of educational services designed to meet the needs of learners across various industries and sectors. Here are some of the services we offer:Corporate Training: We offer customized corporate training programs designed to improve the performance of employees and meet the specific needs of organizations.',
        textlinks: 'We provide academic tutoring services for students at various levels of education, including primary, secondary, and higher education',
        description: 'We provide professional development courses and programs designed to enhance the knowledge and skills of professionals across various industries.'
    },
    {
        id: 2,
        classnames: 'toggle-title',
        title: 'Who can benefit from Nstructive our services',
        text: 'Students at various levels of education, including primary, secondary, higher education, and continuing education, can benefit from our services. We provide academic tutoring, test preparation, and continuing education courses designed to help learners enhance their knowledge and skills.',
        textlinks: 'Individuals seeking to make a career change or transition can benefit from our career counseling services. We help learners identify their career goals and develop a plan for achieving them.',
        description: 'Nstructive educational services are designed to benefit a wide range of learners, professionals, and organizations seeking to enhance their knowledge, skills, and professional development.'
    },
    {
        id: 3,
        classnames: 'toggle-title',
        title: 'What makes our services unique',
        text: 'Nstructive Global Services is committed to providing high-quality educational services that are tailored to the specific needs of learners and organizations.',
        textlinks: 'We believe that every learner and organization is unique and has specific needs. We work closely with our clients to customize our educational services to meet their specific needs, goals, and objectives.',
        description: ' Our instructors are subject matter experts with years of practical experience in their respective fields. They bring real-world knowledge and expertise to our educational programs, which help learners to gain a practical understanding of the subject matter.'
    },
    {
        id: 4,
        classnames: 'toggle-title',
        title: 'What are the qualifications of our instructors',
        text: 'At Nstructive Global Services, we pride ourselves on hiring instructors who are subject matter experts with extensive experience in their respective fields. Our instructors hold advanced degrees in their fields and have years of practical experience in both industry and academia.',
        textlinks: 'Our instructors hold advanced degrees such as masters degrees, doctorates, or professional certifications in their respective fields. They have completed rigorous academic programs and have demonstrated a deep understanding of the subject matter.',
        description: 'Our instructors have experience teaching and mentoring learners at various levels of education. They have developed effective teaching methodologies and have a passion for educating learners.'
    },
    {
        id: 5,
        classnames: 'toggle-title',
        title: 'What support do you offer to students and organization',
        text: 'At Nstructive Global Services, we offer a range of support services to ensure that our students and organizations have a positive learning experience.',
        textlinks: 'Our instructors provide personalized academic support to help students achieve their learning goals. They are available to answer questions, provide feedback, and offer guidance throughout the learning process.',
        description: 'We offer technical support to ensure that our learners have access to the tools and resources they need to complete their coursework. Our technical support team is available to help with any technical issues that may arise.'
    },
    {
        id: 6,
        classnames: 'toggle-title',
        title: 'What is the format of your educational programs (online, in-person, or hybrid)',
        text: 'At Nstructive Global Services, we offer a range of educational programs in different formats to meet the diverse needs of our learners.',
        textlinks: 'Our online programs are designed for learners who prefer the flexibility and convenience of studying from home or from anywhere with an internet connection. Online programs typically include recorded lectures, reading materials, assignments, and assessments that learners can access at their own pace. Our online programs may also include live sessions with instructors and other learners to facilitate interaction and collaboration.',
        description: 'at Nstructive Global Services, we offer educational programs in different formats to meet the diverse needs of our learners. Our programs can be delivered online, in-person, or in a hybrid format, depending on the preferences and requirements of our learners and partner organizations.'
    },
    {
        id: 7,
        classnames: 'toggle-title',
        title: 'How does Nstructive ensure the quality of its educational programs',
        text: 'Nstructive is committed to providing high-quality educational programs that meet the needs of learners and partner organizations. ',
        textlinks: 'We use a range of tools and methods to evaluate the effectiveness of our programs, including learner feedback, assessment data, and performance metrics.',
        description: 'We are committed to continuous improvement and regularly review and refine our educational programs to ensure that they are meeting the evolving needs of our learners and partner organizations.'
    },
    {
        id: 8,
        classnames: 'toggle-title',
        title: 'How can I enroll in an Nstructive educational program',
        text: 'Choose the program that best fits your needs and qualifications. If you are not sure which program is right for you, contact our admissions team for guidance.',
        textlinks: ' Visit our website to learn about the educational programs we offer and the qualifications required for each program.',
        description: 'We use a range of assessment tools and methods to measure learner performance and evaluate the effectiveness of our programs.'
    },
    {
        id: 9,
        classnames: 'toggle-title',
        title: 'How does Nstructive provide support to learners',
        text: 'Each learner is assigned a dedicated advisor who is available to answer questions, provide guidance, and offer support throughout the program',
        textlinks: 'Nstructive provides comprehensive support to learners through a range of services, including technical support, academic advising, and student services. ',
        description: ' We offer technical support to ensure that our learners have access to the tools and resources they need to complete their coursework.'
    },
    {
        id: 10,
        classnames: 'toggle-title',
        title: 'What is the duration of Nstructive educational programs',
        text: 'Some of our programs are short-term and can be completed in a matter of weeks or months, while others are longer-term and may take several years to complete.',
        textlinks: 'The duration of Nstructive educational programs varies depending on the program and the level of study.',
        description: 'We understand that learners have different needs and schedules, so we offer flexible scheduling options to accommodate a wide range of needs.'
    },
];

export default dataAccordion;