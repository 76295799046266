import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import datacourses from '../homeanimate/dataBox'
class CoursesBox extends Component {
    render() {
        return (
            <div className="flat-courses clear-sub-columns clearfix isotope-courses style2">
                {
                    datacourses.map(data =>(
                        <div className={data.classnames} data-animation={data.dataanimate} data-animation-delay={data.delay} data-animation-offset={data.offset} key={data.id}>
                            <div className="course-border border-f-e6f3ff border-ra4 transition-vline">
                                <div className="course-img img-vline">
                                    <Link to="#"><img src={data.srcimg} alt="nstructive" /></Link>
                                    <div className="overlay">
                                        <span className="vline" />
                                        <span className="vline vline-bottom" />
                                    </div>
                                </div>
                                <div className="course-content">
                                    <div className="text-wrap border-bt-e6f3ff">
                                        <h6 className="title"><Link to="/courses-single" onClick={() => {window.location.href="/courses-single"}}>{data.title}</Link></h6>
                                        <p className="teacher"><Link to="#">{data.teacher}</Link></p>
                                        <p className="description">{data.description}</p>
                                    </div>
                                    <div className="wrap-rating-price">
                                        <div className="wrap-rating">
                                            <ul className="list star-list">
                                                <li><i className={data.icon01} /></li>
                                                <li><i className={data.icon02} /></li>
                                                <li><i className={data.icon03} /></li>
                                                <li><i className={data.icon04} /></li>
                                                <li><i className={data.icon05} /></li>
                                            </ul>
                                            <span>{data.ratting}</span>
                                        </div>
                                        <span className="price">{data.pricing}</span>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    ))
                }
            </div>
        );
    }
}

export default (CoursesBox);