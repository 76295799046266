import React, { Component } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";

class CounterComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisibilitySensor: {
                textbookSolved: true,
                sme: true,
                textbookSolution: true, 
            },
        };
    }

    toggleVisibilitySensor = (isVisible, key) => {
        if (isVisible) {
            this.setState({ isVisibilitySensor: { ...this.state.isVisibilitySensor, [key]: false } });
        }
    }

    render() {
        return (
            <div className="flat-counter style2">
                <div className="wrap-counter">
                    <div className="container">
                        <div className="counters clear-sub-columns style2">
                            <div className="counter style2 one-of-three">
                                <div className="overlay">
                                    <img src="images/img-hover-1.png" alt="nstructive" className="img-hover-1" />
                                    <img src="images/img-hover-2.png" alt="Bookflare" className="img-hover-2" />
                                </div>
                                <div className="icon">
                                    <span className="icon-online-learning">
                                        <span className="path1" /><span className="path2" /><span className="path3" /><span
                                            className="path4" /><span className="path5" /><span className="path6" /><span
                                            className="path7" /><span className="path8" /><span className="path9" /><span
                                            className="path10" /><span className="path11" /><span className="path12" /><span
                                            className="path13" /><span className="path14" /><span className="path15" /><span
                                            className="path16" /><span className="path17" /><span className="path18" /><span
                                            className="path19" /><span className="path20" /><span className="path21" /><span
                                            className="path22" /><span className="path23" /><span className="path24" /><span
                                            className="path25" /><span className="path26" /><span className="path27" /><span
                                            className="path28" />
                                    </span>
                                </div>
                                <div className="content">
                                    <VisibilitySensor partialVisibility
                                        offset={{ bottom: 20 }}
                                        active={this.state.isVisibilitySensor.textbookSolved}
                                        onChange={isVisible => this.toggleVisibilitySensor(isVisible, 'textbookSolved')}>
                                        {({ isVisible }) => isVisible ?
                                            <CountUp end={250} suffix='+' className="numb color-2ba2db" separator=',' /> : <CountUp end={0} className="numb color-2ba2db" />}
                                    </VisibilitySensor>
                                    <h6 className="title color-2ba2db">TEXTBOOKS SOLVED</h6>
                                </div>
                            </div>
                            <div className="counter style2  one-of-three">
                                <div className="overlay">
                                    <img src="images/img-hover-1.png" alt="nstructive" className="img-hover-1" />
                                    <img src="images/img-hover-2.png" alt="Bookflare" className="img-hover-2" />
                                </div>
                                <div className="icon">
                                    <span className="icon-teacher">
                                        <span className="path1" /><span className="path2" /><span className="path3" /><span
                                            className="path4" /><span className="path5" /><span className="path6" /><span
                                            className="path7" /><span className="path8" /><span className="path9" /><span
                                            className="path10" /><span className="path11" /><span className="path12" /><span
                                            className="path13" /><span className="path14" /><span className="path15" /><span
                                            className="path16" /><span className="path17" /><span className="path18" /><span
                                            className="path19" /><span className="path20" /><span className="path21" /><span
                                            className="path22" /><span className="path23" /><span className="path24" /><span
                                            className="path25" /><span className="path26" /><span className="path27" /><span
                                            className="path28" /><span className="path29" /><span className="path30" /><span
                                            className="path31" /><span className="path32" /><span className="path33" /><span
                                            className="path34" /><span className="path35" /><span className="path36" /><span
                                            className="path37" /><span className="path38" /><span className="path39" /><span
                                            className="path40" /><span className="path41" /><span className="path42" /><span
                                            className="path43" /><span className="path44" /><span className="path45" /><span
                                            className="path46" /><span className="path47" /><span className="path48" /><span
                                            className="path49" /><span className="path50" />
                                    </span>
                                </div>
                                <div className="content">
                                    <VisibilitySensor partialVisibility
                                            offset={{ bottom: 20 }}
                                            active={this.state.isVisibilitySensor.sme}
                                            onChange={isVisible => this.toggleVisibilitySensor(isVisible, 'sme')}>
                                            {({ isVisible }) => isVisible ?
                                                <CountUp end={100} suffix='+' className="numb color-2ba2db" separator=',' /> : <CountUp end={0} className="numb color-2ba2db" />}
                                    </VisibilitySensor>
                                    <h6 className="title color-2ba2db">SUBJECT MATTER EXPERTS</h6>
                                </div>
                            </div>
                            <div className="counter style2  one-of-three">
                                <div className="overlay">
                                    <img src="images/img-hover-1.png" alt="nstructive" className="img-hover-1" />
                                    <img src="images/img-hover-2.png" alt="Bookflare" className="img-hover-2" />
                                </div>
                                <div className="icon">
                                    <span className="icon-education">
                                        <span className="path1" /><span className="path2" /><span className="path3" /><span
                                            className="path4" /><span className="path5" /><span className="path6" /><span
                                            className="path7" /><span className="path8" /><span className="path9" /><span
                                            className="path10" /><span className="path11" /><span className="path12" /><span
                                            className="path13" /><span className="path14" /><span className="path15" /><span
                                            className="path16" /><span className="path17" /><span className="path18" /><span
                                            className="path19" /><span className="path20" /><span className="path21" /><span
                                            className="path22" /><span className="path23" /><span className="path24" /><span
                                            className="path25" /><span className="path26" />
                                    </span>
                                </div>
                                <div className="content">
                                    <VisibilitySensor partialVisibility
                                        offset={{ bottom: 20 }}
                                        active={this.state.isVisibilitySensor.textbookSolution}
                                        onChange={isVisible => this.toggleVisibilitySensor(isVisible, 'textbookSolution')}>
                                        {({ isVisible }) => isVisible ?
                                            <CountUp end={1000000} suffix='+' className="numb color-2ba2db" separator=',' /> : <CountUp end={0} className="numb color-2ba2db" />}
                                    </VisibilitySensor>
                                    <h6 className="title color-2ba2db">TEXTBOOK SOLUTIONS</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CounterComponent;