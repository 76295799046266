import React, { Component } from 'react';
import { BottomBar, CoursesBox, Footer, Header, Instructor, Loader, Paramater, SlideTestimonial, TitleHeading, TopBar } from '../layouts/general';
import { EventBoxs, Featured, FormApply, TabCourses,Slider } from '../layouts/home01';

class Home01 extends Component {
    constructor(props) {
        super(props);
        this.state = {
           headers: [
               {
                   id: 1,
                   names: 'Home'
               }
           ],
           titleCourses: [
               {
                   id: 1,
                   title: 'Discover Our Popular Courses',
                   classTitle: 'title',
                    classDescription: 'description',
                   description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
               }
           ],
           titleIntructor: [
                {
                    id: 1,
                    classTitle: 'title',
                    classDescription: 'description',
                    title: 'Our Skilled Instructors',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                }
            ],
            titleEvents: [
                {
                    id: 1,
                    classTitle: 'title',
                    classDescription: 'description',
                    title: 'Upcoming Events',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.'
                }
            ],
            titleTestimonial: [
                {
                    id: 1,
                    classTitle: 'title',
                    classDescription: 'description',
                    title: 'What Our Teacher’s Say',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                }
            ],
        }
    }
    render() {
        return (
            <div className="bg-body3">
                <div className="boxed">
                    <Loader />
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }
                    <Slider />
                    <Featured />
                    <section className="flat-popular-courses style2 v2">
                        <div className="container">
                            {
                                this.state.titleCourses.map(data =>(
                                    <TitleHeading data={data} key={data.id}/>
                                ))
                            }
                            <CoursesBox />
                        </div>
                    </section>
                    <section className="flat-register-now">
                        <div className="overlay" />
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5">
                                    <FormApply />
                                </div>
                                <div className="col-lg-7">
                                    <TabCourses />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="flat-skilled-instructors">
                        <div className="container">
                            {
                                this.state.titleIntructor.map(data =>(
                                    <TitleHeading data={data} key={data.id}/>
                                ))
                            }
                            <Instructor />
                        </div>
                    </section>
                    <section className="flat-upcoming-events style1">
                        <div className="container">
                            {
                                this.state.titleEvents.map(data =>(
                                    <TitleHeading data={data} key={data.id}/>
                                ))
                            }
                            <div className="flat-events style2">
                                <EventBoxs />
                            </div>
                        </div>
                    </section>
                    <section className="flat-testimonials style1">
                        <div className="container">
                            {
                                this.state.titleTestimonial.map(data =>(
                                    <TitleHeading data={data} key={data.id}/>
                                ))
                            }
                            <SlideTestimonial />
                        </div>
                    </section>
                    <section className="flat-feature parallax parallax2">
                        <div className="container">
                            <Paramater />
                        </div>
                    </section>
                    <Footer />
                    <BottomBar />
                </div>
            </div>
        );
    }
}

export default Home01;