const menus = [
    {
        id: 1,
        name: 'HOME',
        linkmenu: '/'
    },
    {
        id: 2,
        name: 'ABOUT US',
        linkmenu: '/about-us'
    },
    {
        id: 3,
        name: 'SERVICES',
        linkmenu: '#',
        namesub: [
            {
                id: 1,
                sub: 'TEXTBOOK SOLUTION',
                links: '/service?servicename=textbook-solution',
                submenu: undefined
            },
            {
                id: 2,
                sub: 'ALT TEXT WRITING',
                links: '/service?servicename=alt-text-writing',
                submenu: undefined
            },
            {
                id: 3,
                sub: 'Q&A SERVICE',
                links: '/service?servicename=qa-service',
                submenu: undefined
            },
            {
                id: 4,
                sub: 'EDITORIAL',
                links: '/service?servicename=editorial',
                submenu: undefined
            },
            {
                id: 5,
                sub: 'ASSIGNMENT HELP',
                links: '/service?servicename=assignment-help',
                submenu: undefined
            },
            {
                id: 6,
                sub: 'ONLINE TUTORING',
                links: '/service?servicename=online-tutoring',
                submenu: undefined
            }
        ],
    },
    {
        id: 4,
        name: 'CONTACT US',
        linkmenu: '/contact-us'
    },
    {
        id: 5,
        name: 'FAQ',
        linkmenu: '/faq'
    },
]

export default menus;