import React, { Component } from 'react';
import { TopBar, Header, TitleHeading, Paramater, Footer, BottomBar, Loader} from '../layouts/general';
import {CounterComponent, Slider } from '../layouts/home03';
import { FormApply, TabCourses } from '../layouts/home01';
import { TabCoaching } from '../layouts/home02';
import { TitleHeading4, CategoryComponent } from '../layouts/home04';

class Home03 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: 'HOME'
                }
            ],
            titleCourses: [
                {
                    id: 1,
                    title: 'Discover Our Popular Courses',
                    classTitle: 'title',
                     classDescription: 'description',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                }
            ],
            titleTestimonial: [
                {
                    id: 1,
                    classTitle: 'title',
                    classDescription: 'description',
                    title: 'What Our Teacher’s Say',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                }
            ],
            titleCategory: [
                {
                    id: 1,
                    title01: 'Our',
                    title02: 'Services',
                    classTitle: 'title color-fff',
                    classspan: 'color-2ba2db',
                    classDescription: 'description color-fff',
                    description: 'At Nstructive, we are dedicated to providing a wide range of educational services that empower individuals to reach their full potential. Whether you are looking to improve your skills in a particular subject, prepare for an exam, or pursue a new career, we have a program that\'s right for you.'
                }
            ],
            titleIntructor: [
                {
                    id: 1,
                    classTitle: 'title',
                    classDescription: 'description',
                    title: 'Our Skilled Instructors',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                }
            ],
            titleCoaching: [
                {
                    id: 1,
                    title: 'Creating Premium Educational Content',
                    classTitle: 'title',
                    classDescription: 'description',
                    description: 'Nstructive, founded in 2020, is one of the leading companies in educational content production across all STEM and Business courses. It is the product of our rigorous planning, hard work, learning, and expertise of delivering quality information to students all around the world.'
                }
            ],
        }
    }
    render() {
        return (
            <div className="boxed">
                <Loader />
                <TopBar />
                {
                    this.state.headers.map(data => (
                        <Header data={data} key={data.id}/>
                    ))
                }             
                <Slider />  
                <CounterComponent />
                <section className="flat-services">
                    <div className="container">
                        {
                            this.state.titleCoaching.map(data =>(
                                <TitleHeading data={data} key={data.id}/>
                            ))
                        }
                        <TabCoaching />
                    </div>
                </section>
                <section className="flat-categories">
                    <div className="container">
                        {
                            this.state.titleCategory.map(data =>(
                                <TitleHeading4 data={data} key={data.id}/>
                            ))
                        }  
                        <CategoryComponent />
                    </div>
                </section>
                <section className="flat-why-choose-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <TabCourses />
                            </div>
                            <div className="col-lg-4 image-overlay">
                                <img src="../images/why.jpg" alt="why choose us - excellence" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="flat-register-now">
                    <div className="overlay" />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 title-box">
                            <div className="heading">
                                <h2 className="title">Together, let's build something great.</h2>
                                <p className="description">At our company, we believe in the power of partnership. By working together, we can leverage our collective skills and expertise to create innovative solutions and achieve shared success. We value open communication, collaboration, and a commitment to quality in all that we do. Join us in our mission to build something great, and let's make a meaningful impact together.</p>
                            </div>
                            </div>
                            <div className="col-lg-5"><FormApply /></div>
                        </div>
                    </div>
                </section>
                <section className="flat-feature parallax parallax2">
                    <div className="container">
                        <Paramater />
                    </div>
                </section>
                <Footer />
                <BottomBar />
            </div>
        );
    }
}

export default Home03;