import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class RecentImage extends Component {
    render() {
        return (
            <div className="widget-about widget-ft">
                <h6 className="widget-title">GET IN TOUCH</h6>
                <div className="list-info">
                    <ul>
                        <li className="phone">
                            <Link to="#">+91 789 571 0403</Link>
                        </li>
                        <li className="mail">
                            <Link to="#">admin@nstructive.co.in</Link>
                        </li>
                    </ul>
                </div>
                <div className="socails">
                    <ul className="list">
                        <li>
                            <a className='social-icon' target="_blank" rel="noreferrer" title="Linkedin" href="https://www.linkedin.com/company/nstructive-global-services-private-limited/"><span className="fa fa-linkedin-square" /></a>
                        </li>
                        <li>
                            <a className='social-icon' target="_blank" rel="noreferrer" title="Facebook" href="https://www.facebook.com/Nstructive-Global-Services-Private-Limited-108692798250362"><span className="fa fa-facebook-official" /></a>
                        </li>
                        <li>
                            <a className='social-icon' target="_blank" rel="noreferrer" title="Instagram" href="https://www.instagram.com/nstructive/"><span className="fab fa-instagram" /></a>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default RecentImage;