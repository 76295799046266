import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Header, TopBar, Paramater, Footer, BottomBar, Loader } from "../layouts/general";

class Privacy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: [
                {
                    id: 1,
                    names: "Pages"
                }
            ],
        }
    }
    render() {
        return (
            <div className="bg-body2">
                <div className="boxed">
                    <Loader />
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id} />
                        ))
                    }
                    <section className="flat-title-page parallax parallax4 style2">
                        <div className="overlay" />
                        <div className="container wrap-title-page flat-text-center">
                            <div className="title-page">
                                <h2 className="title font-weight-700"><Link to="#">Privacy Policy</Link></h2>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <div className="content-wrap privacy-page">
                        <div className="container">
                            <div className="content-page wrap">
                                <div className="row">
                                    <div className="col flat-text-justify">
                                        <div>
                                            <div>&nbsp;</div>
                                            <div>&nbsp;</div>
                                            <div>&nbsp;</div>
                                            <div>The following privacy statement applies to <a href="/">https://nstructive.co.in/</a> and the Nstructive website. You must adhere to this Policy when using Nstructive"s website, email services, and interactive features.</div>
                                            <div>We pledge to protect your privacy because we value it. Before using our website, carefully read the following.</div>
                                        </div>
                                        <div>&nbsp;</div>
                                        <div>
                                            <h6 className="heading">Information We Collect</h6><div>&nbsp;</div>
                                            <div>When our customers enter or upload information into the website while using the Services, "Customer Data" may be processed by us as a result. When you submit the contact form (or any other online form on the website), we may ask you for personal information such your first and last names, email address, and phone number.</div>
                                        </div>
                                        <div>
                                            <div>&nbsp;</div>
                                            <h6 className="heading">Other Data that we collect</h6><div>&nbsp;</div>
                                            <div><b>Log Data: </b>When you visit or use our apps or services, our servers automatically record information. In log files, this information is stored.</div>
                                            <div>Other tracking technologies and cookies</div>
                                            <div><b>Google Analytics:</b> At the moment, we utilise Google Analytics to measure website activity and gather anonymous statistics. The programme offers data on user behaviors, location, city, websites that users have visited previously, and devices. Your IP address could be recorded by Google Analytics, but no other private data will be.</div>
                                            <div><b>Cookies:</b> To track users" online behavior, we utilise cookies to collect data. Based on the actions of our previous users, we utilise this information to improve the usability of our website. This monitoring technology provides us with some type of feedback on the website"s relevancy and degree of comprehension.</div>
                                        </div>
                                        <div>&nbsp;</div>
                                        <div>
                                            <h6 className="heading">Security and Information Protection</h6><div>&nbsp;</div>
                                            <div>We restrict all types of unauthorised access to the database of our website. The information we collect is always kept in secure locations that are protected from threats and theft. Your information will only be used for internal record-keeping and information processing. Except in the case of a specific request from you that may involve another third party, we highly discourage disclosing your information to any other parties.</div>
                                        </div>
                                        <div>&nbsp;</div>
                                        <div>
                                            <h6 className="heading">Information Sharing</h6><div>&nbsp;</div>
                                            <div>The data gathered on this website is our property. Without the customer"s, in this case you, approval, we do not distribute, sell, trade, rent, or reveal your personal information to any third party.</div>
                                        </div>
                                        <div>&nbsp;</div>
                                        <div>
                                            <h6 className="heading">Changes in Privacy Policy</h6><div>&nbsp;</div>
                                            <div>Nstructive has the right to modify or amend the privacy policy whenever necessary. When giving us your information, please confirm that you understand and agree to our Privacy policy. You are also urged to periodically review the policies to make sure you are OK with any updates. If there are any changes to the website"s privacy statement, we might let you know.</div>
                                        </div>
                                        <div>&nbsp;</div>
                                        <div>
                                            <h6 className="heading">Contact Information</h6><div>&nbsp;</div>
                                            <div>Inquiries regarding our services or this privacy statement can be sent to us</div>
                                            <div>Contact us by email at <a href="mailto:admin@nstructive.co.in">admin@nstructive.co.in</a>. if you have any questions or concerns about our policy.</div>
                                        </div>
                                        <div>&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className="flat-feature parallax parallax2">
                        <div className="container">
                            <Paramater />
                        </div>
                    </section>
                    <Footer />
                    <BottomBar />
                </div>
            </div>
        );
    }
}

export default Privacy;