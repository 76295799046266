import React, { Component } from 'react';
import {Link} from 'react-router-dom'

class BannerTitle extends Component {
    render() {
        return (
            <section className={this.props.data.classnames}>
                <div className={this.props.data.classoverlay} /> 
                <div className="container wrap-title-page bg-img ">
                    <div className="title-page">
                        <h2 className="title font-weight-700"><Link to="#">{this.props.data.titles}</Link></h2>
                        <p className="text">{this.props.data.description}</p>
                    </div>
                    <div className={this.props.data.classbread}>
                        <ul>
                            <li><Link to="/" onClick={() => {window.location.href='/'}}>{this.props.data.name01}</Link></li>
                            <li><Link to={this.props.data.link01} onClick={() => {window.location.href=this.props.data.link01}}>{this.props.data.name02}</Link></li>
                        </ul>
                    </div>
                    <img src={this.props.data.srcimg} alt="nstructive" className="bg-breadcrumbs" />
                </div>
            </section>
        );
    }
}

export default BannerTitle;