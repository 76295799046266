import React, { Component } from 'react';
import { Link } from 'react-router-dom'
class EventSingleComponent extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-lg-8 col-md-12 ">
                    <div className="content-page-wrap ">
                        <div className="event-detail">
                            <div className="img-single">
                                <Link to="#"><img src="images/events/event-detail.jpg" alt="nstructive" /></Link>
                            </div>
                            <div className="content">
                                <div className="heading border-bt">
                                    <h6 className="title"><Link to="#">Open House: Adult Degree Completion Program</Link></h6>
                                    <p className="description">Lorem Ipsn gravida nibh velalito auctor alipuet. Aenean sollicitudin,
                                        lorem quis bibendum auci elit conse ipsutis sem nibh id elit. Duis sed odio sit amet nibh
                                        vulputate cursus.</p>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="author">
                                            <div className="author-avata">
                                                <img src="images/events/author-teacher.jpg" alt="nstructive" />
                                            </div>
                                            <div className="author-info">
                                                <h4 className="name"><Link to="#">Charlotte H. Bronk</Link></h4>
                                                <p className="text color-192836">Founder and Сhairman at Global Innovation Catalyst
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="wrap-btn flat-text-right">
                                            <Link to="#" className="flat-button btn-get-ticket">GET TICKET</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flat-tabs bg-fff border-ra4 border-f-e6f3ff">
                            <ul className="tab-title style1 clearfix border-bt-e6f3ff">
                                <li className="item-title  overview">
                                    <span className="inner">OVERVIEW</span>
                                </li>
                                <li className="item-title event">
                                    <span className="inner">EVENT PARTICIPANTS</span>
                                </li>
                                <li className="item-title map">
                                    <span className="inner">MAP</span>
                                </li>
                            </ul>
                            <div className="tab-content-wrap">
                                <div className="tab-content">
                                    <div className="item-content">
                                        <div className="img-single-small">
                                            <img src="images/events/img-small.png" alt="nstructive" />
                                        </div>
                                        <div className="text-wrap">
                                            <h6 className="title"><Link to="#">Limitless Learning, Limitless Possibilities</Link></h6>
                                            <p className="text">Lorem Ipsn gravida nibh vel velit auctor aliquet. Aenean
                                                sollicitudin, lorem quis bibendum auci elit consequat ipsutis sem nibh id elit. Duis
                                                sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accum dolor same
                                                mipsum rvelit. </p>
                                        </div>
                                        <div className="text-wrap">
                                            <h6 className="title"><Link to="#">About This Event</Link></h6>
                                            <p className="text">Per inceptos himenaeos. Mauris in erat justo. Nullam ac urna eu
                                                felis dapibus condimentum sit amet a augue. Sed non mauris vitae erat consequat
                                                auctor eu in elit. Class aptent tacitio sociosqu ad litora torquent.</p>
                                        </div>
                                        <div className="img-single">
                                            <img src="images/events/event-detail-2.jpg" alt="nstructive" />
                                        </div>
                                        <div className="text-wrap">
                                            <h6 className="title"><Link to="#">Learn and Master Motion Graphics and Visual Effects in
                                                    After Effects</Link></h6>
                                            <p className="text">
                                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a
                                                piece of classi-cal Latin literature from 45 BC, making it over 2000 years old.
                                                Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked
                                                up one of the more obscure Latin words, consecte-tur, from a Lorem Ipsum passage,
                                                and going through the cites of the word in classical literature, discovered the
                                                undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de
                                                Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45
                                                BC. This book is a treatise on the theory of ethics, very popular during the
                                                Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes
                                                from a line in section 1.10.32.
                                            </p>
                                            <p className="text">
                                                The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those
                                                interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by
                                                Cicero are also reproduced in their exact original form, accompanied by English
                                                versions from the 1914.
                                            </p>
                                            <div className="wrap-btn">
                                                <Link to="#" className="flat-button btn-google border-ra4">+ GOOGLE CALENDAR</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content">
                                    <div className="item-content">
                                        <div className="img-single-small">
                                            <img src="images/events/img-small.png" alt="nstructive" />
                                        </div>
                                        <div className="text-wrap">
                                            <h6 className="title"><Link to="#">Limitless Learning, Limitless Possibilities</Link></h6>
                                            <p className="text">Lorem Ipsn gravida nibh vel velit auctor aliquet. Aenean
                                                sollicitudin, lorem quis bibendum auci elit consequat ipsutis sem nibh id elit. Duis
                                                sed odio sit amet nibh vulputate cursus a sit amet mauris. Morbi accum dolor same
                                                mipsum rvelit. </p>
                                        </div>
                                        <div className="text-wrap">
                                            <h6 className="title"><Link to="#">About This Event</Link></h6>
                                            <p className="text">Per inceptos himenaeos. Mauris in erat justo. Nullam ac urna eu
                                                felis dapibus condimentum sit amet a augue. Sed non mauris vitae erat consequat
                                                auctor eu in elit. Class aptent tacitio sociosqu ad litora torquent.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content">
                                    <div className="item-content">
                                        <h1>No Map</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="col-lg-4 col-md-12 ">
                    <div className="sidebar-right">
                        <div className="widget-counter">
                            <div className="flat-counter clearfix flat-text-center count-time" data-day={20} data-month={12}
                                data-year={2019} data-hour={12} data-minute={12} data-second={12}>
                                <div className="counter one-of-four days">
                                    <div className=" border-f-e6f3ff border-ra4">
                                        <div className="numb">
                                            12
                                        </div>
                                        <div className="text">
                                            DAYS
                                        </div>
                                    </div>
                                </div>
                                <div className="counter one-of-four hours ">
                                    <div className=" border-f-e6f3ff border-ra4">
                                        <div className="numb">
                                            9
                                        </div>
                                        <div className="text">
                                            HOURS
                                        </div>
                                    </div>
                                </div>
                                <div className="counter one-of-four minutes">
                                    <div className=" border-f-e6f3ff border-ra4">
                                        <div className="numb">
                                            26
                                        </div>
                                        <div className="text">
                                            MINUTES
                                        </div>
                                    </div>
                                </div>
                                <div className="counter one-of-four seconds ">
                                    <div className=" border-f-e6f3ff border-ra4">
                                        <div className="numb">
                                            59
                                        </div>
                                        <div className="text">
                                            SECONDS
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="widget-event-detail border-f-e6f3ff">
                            <h4 className="widget-title flat-text-center">Event Details</h4>
                            <p className="description flat-text-center">Netus et malesuad fames ac tmgest blandi cursus risuar etiam
                                non puam lacus. </p>
                            <ul className="list-detail">
                                <li className="slot clearfix">
                                    <span className="title">TOTAL SLOT</span>
                                    <span className="value"> 150</span>
                                </li>
                                <li className="cost clearfix">
                                    <span className="title">COST</span>
                                    <span className="value"> $40.00 </span>
                                </li>
                                <li className="start-time clearfix">
                                    <span className="title">START TIME</span>
                                    <span className="value"> 9 : 00 AM</span>
                                </li>
                                <li className="end-time clearfix">
                                    <span className="title">END TIME</span>
                                    <span className="value"> 3 : 00 PM</span>
                                </li>
                                <li className="location clearfix">
                                    <span className="title">LOCATION</span>
                                    <span className="value">MELBOURNE</span>
                                </li>
                            </ul>
                            <div className="wrap-btn flat-text-center">
                                <Link to="#" className="flat-button btn-book-now border-ra4">BOOK NOW!</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EventSingleComponent;