import React, { Component } from 'react';
import {Link} from 'react-router-dom';
class TabAboutComponent extends Component {
    render() {
        return (
            <div className="flat-about-detail ">
                <div className="container">
                    <div className="heading">
                        <h6 className="title">Whatever discipline you choose, you’ll find your old ideas challenged and your new
                            ideas embraced. Goldsmiths is a place where together we are creating the knowledge of the future.</h6>
                    </div>
                </div>
                <div className="flat-tabs bg-fff border-ra4 border-f-e6f3ff style2">
                    <ul className="tab-title style2 clearfix border-bt-e6f3ff  flat-text-center">
                        <li className="item-title  overview">
                            <span className="inner">WHO WE ARE</span>
                        </li>
                        <li className="item-title event">
                            <span className="inner">WHY NSTRUCTIVE</span>
                        </li>
                        <li className="item-title review">
                            <span className="inner">OUR STORY</span>
                        </li>
                    </ul>
                    <div className="tab-content-wrap">
                        <div className="tab-content">
                            <div className="item-content">
                                <div className="container">
                                    <div className="flat-imgbox">
                                        <div className="imgbox">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="imgbox-content left">
                                                        <h3 className="title">
                                                            Mission and Values
                                                        </h3>
                                                        <p className="text">
                                                        At Nstructive, we are a team of passionate professionals who are committed to transforming education by leveraging technology and expertise to empower learners and educators across the world. Our team comprises 
                                                        experts in education, technology, and business, who bring a wealth of knowledge and experience to our organization.
                                                        </p>
                                                        <p className="text">
                                                        we are driven by a passion for education and a commitment to helping our clients achieve their full potential. We believe that education is the key to a better future, and 
                                                        we are dedicated to providing solutions that help our clients succeed in today's rapidly changing world.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="imgbox-img">
                                                        <Link to="#"><img src="images/about/a1.png" alt="nstructive" /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="imgbox">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="imgbox-img">
                                                        <Link to="#"><img src="images/about/a2.png" alt="nstructive" /></Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="imgbox-content right">
                                                        <h3 className="title">
                                                            Global Reach
                                                        </h3>
                                                        <p className="text">
                                                        Our company was founded with a mission to revolutionize education by providing innovative, high-quality 
                                                        solutions to meet the ever-evolving needs of our clients. We specialize in providing a wide range of education 
                                                        services, including e-learning solutions, content development, assessment and evaluation services, and 
                                                        consultancy services.
                                                        </p>
                                                        <p className="text">
                                                        We invite you to explore our website to
                                                         learn more about our team, our services, and our commitment to transforming education. We look forward to 
                                                         partnering with you to drive success and empower learners and educators across the world.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-content">
                            <div className="item-content">
                                <div className="container">
                                    <div className="flat-imgbox">
                                        <div className="imgbox">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="imgbox-content left">
                                                        <h3 className="title">
                                                            Education and Technology
                                                        </h3>
                                                        <p className="text">
                                                        Our education services are designed to meet the diverse needs of learners at every stage of their educational 
                                                        journey. Whether you are a student seeking to enhance your knowledge and skills, an educator looking to improve 
                                                        your teaching practices, or an institution seeking to enhance 
                                                        the quality of your educational programs, we have the expertise and experience to help you achieve your goals.
                                                        </p>
                                                        <p className="text">
                                                        We provide a wide range of assessment and evaluation services that help institutions and organizations measure 
                                                        the effectiveness of their educational programs. Our assessment and evaluation services are designed to provide 
                                                        actionable insights that can be used to improve the quality of education.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="imgbox-img">
                                                        <Link to="#"><img src="images/about/a3.png" alt="nstructive" /></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="imgbox">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="imgbox-img">
                                                        <Link to="#"><img src="images/about/a4.png" alt="nstructive" /></Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="imgbox-content right">
                                                        <h3 className="title">
                                                            Innovation
                                                        </h3>
                                                        <p className="text">
                                                        We offer consultancy services to educational institutions, governments, and corporations on a wide range of 
                                                        education-related issues. Our team of experts provides strategic guidance and practical solutions to help our 
                                                        clients achieve their educational goals.
                                                        </p>
                                                        <p className="text">
                                                        We specialize in developing customized e-learning solutions that are designed to meet the specific needs of 
                                                        learners and educators. Our solutions are interactive, 
                                                        engaging, and highly effective, and are designed to promote active learning and knowledge retention.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-content">
                            <div className="item-content">
                                <div className="container">
                                    <div className="flat-imgbox">
                                        <div className="imgbox">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="imgbox-img">
                                                        <Link to="#"><img src="images/about/a5.png" alt="nstructive" /></Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="imgbox-content right">
                                                        <h3 className="title">
                                                            The Evolution
                                                        </h3>
                                                        <p className="text">
                                                        Our journey has been marked by a series of milestones and achievements. 
                                                        In 2020, we launched our first e-learning solution, which quickly gained 
                                                        popularity among learners and educators. In 2021, we expanded our services 
                                                        to include content development, assessment and evaluation services, and consultancy 
                                                        services, enabling us to provide comprehensive education solutions to our clients.
                                                        </p>
                                                        <p className="text">
                                                        Today, we are proud to be a trusted partner to educational institutions, governments, 
                                                        and corporations across the world. We are committed to delivering education services 
                                                        that are of the highest quality and value, and we are constantly innovating and evolving 
                                                        to meet the changing needs of our clients.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TabAboutComponent;