const dataTab = [
    {
        id: 1,
        classnames: 'course one-of-four text-2ebd59 Marketing Design',
        imgsrc: 'images/courses/courses-gird-v1-1.jpg',
        title: 'Complete Python Bootcamp',
        teacher: 'Ana Murphy',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: 'FREE'
    },
    {
        id: 2,
        classnames: 'course one-of-four text-ea0042 Marketing ',
        imgsrc: 'images/courses/courses-gird-v1-2.jpg',
        title: 'Vue JS 2 - The Complete Guide',
        teacher: 'Rosy Janner',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: '$49.00'
    },
    {
        id: 3,
        classnames: 'course one-of-four text-c100ea Marketing Design',
        imgsrc: 'images/courses/courses-gird-v1-3.jpg',
        title: 'Complete Java Masterclass',
        teacher: 'Tom Steven',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: '$24.00'
    },
    {
        id: 4,
        classnames: 'course one-of-four text-256cff Business',
        imgsrc: 'images/courses/courses-gird-v1-4.jpg',
        title: 'Complete Python Bootcamp',
        teacher: 'Ana Murphy',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: '$89.00'
    },
    {
        id: 5,
        classnames: 'course one-of-four text-8828ff Design',
        imgsrc: 'images/courses/courses-gird-v1-5.jpg',
        title: 'jQuery Mobile for Beginners',
        teacher: 'Ana Murphy',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: '$24.00'
    },
    {
        id: 6,
        classnames: 'course one-of-four text-0dacff Leadership',
        imgsrc: 'images/courses/courses-gird-v1-6.jpg',
        title: 'Angular 6 - The Complete Guide',
        teacher: 'Rosy Janner',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: '$49.00'
    },
    {
        id: 7,
        classnames: 'course one-of-four text-536dfe Design',
        imgsrc: 'images/courses/courses-gird-v1-7.jpg',
        title: 'Complete Java Masterclass',
        teacher: 'Tom Steven',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: '$24.00'
    },
    {
        id: 8,
        classnames: 'course one-of-four text-ffbf13 Leadership',
        imgsrc: 'images/courses/courses-gird-v1-8.jpg',
        title: 'React 16 - The Complete Guide',
        teacher: 'Mark Henrey',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: '$89.00'
    },
    {
        id: 9,
        classnames: 'course one-of-four text-2ebd59 Design',
        imgsrc: 'images/courses/courses-gird-v1-9.jpg',
        title: 'Node.js Developer Course',
        teacher: 'Ana Murphy',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: 'FREE'
    },
    {
        id: 10,
        classnames: 'course one-of-four text-ff562f Leadership Photography',
        imgsrc: 'images/courses/courses-gird-v1-10.jpg',
        title: 'The Ultimate Drawing Course',
        teacher: 'Rosy Janner',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: '$49.00'
    },
    {
        id: 11,
        classnames: 'course one-of-four text-0dacff Business Leadership Photography',
        imgsrc: 'images/courses/courses-gird-v1-11.jpg',
        title: 'Learn Python & Ethical Hacking',
        teacher: 'Tom Steven',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: '$24.00'
    },
    {
        id: 12,
        classnames: 'course one-of-four text-2ebd59 Photography',
        imgsrc: 'images/courses/courses-gird-v1-12.jpg',
        title: 'Advanced CSS Flexbox',
        teacher: 'Mark Henrey',
        description: 'Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.',
        count: '5.0 (980)',
        price: 'FREE'
    }
]

export default dataTab;