import React, { Component } from 'react'
import {Link, withRouter} from 'react-router-dom'
import dataintructors from '../home01/dataIntructor'
import intrucHome02 from '../home02/intrucHome02'
class Instructor extends Component {
    render() {
        const {location} = this.props;
        const mainContent = () => {
            if(location.pathname === "/"){
                return(
                    <>
                        {
                            dataintructors.map(data => (
                                <div className="col-lg-4 col-md-12" key={data.id}>
                                    <div className={data.classnames}>
                                        <div className="avata-member">
                                            <img src={data.src} alt="nstructive"
                                                    className="transition-img" />
                                        </div>
                                        <div className="info-member">
                                            <h6 className="name">{data.name}</h6>
                                            <p className="position">{data.position}</p>
                                            <p className="description">
                                                {data.description}
                                            </p>
                                        </div>
                                        <div className={data.classicon}>
                                            <ul className="list">
                                                <li className="facbook"><Link to="#"><i className="fa fa-facebook" /></Link></li>
                                                <li className="instagram"><Link to="#"><i className="fab fa-instagram" /></Link></li>
                                                <li className="skype"><Link to="#"><i className="fab fa-skype" /></Link></li>
                                                <li className="github"><Link to="#"><i className="fab fa-github" /></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </>
                )
            }else if(location.pathname === "/home-02" || location.pathname === "/home-03"){
                return(
                    <>
                        {
                            intrucHome02.map(data => (
                                <div className="col-lg-4 col-md-12" key={data.id}>
                                    <div className={data.classnames}>
                                        <div className="avata-member">
                                            <img src={data.src} alt="nstructive"
                                                    className="transition-img" />
                                        </div>
                                        <div className="info-member">
                                            <h6 className="name">{data.name}</h6>
                                            <p className="position">{data.position}</p>
                                            <p className="description">
                                                {data.description}
                                            </p>
                                        </div>
                                        <div className={data.classicon}>
                                            <ul className="list">
                                                <li className="facbook"><Link to="#"><i className="fa fa-facebook" /></Link></li>
                                                <li className="instagram"><Link to="#"><i className="fab fa-instagram" /></Link></li>
                                                <li className="skype"><Link to="#"><i className="fab fa-skype" /></Link></li>
                                                <li className="github"><Link to="#"><i className="fab fa-github" /></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </>
                )
            }
        }
        return (
            <div className="flat-team row">
                {mainContent()}
            </div>
        );
    }
}

export default withRouter(Instructor);