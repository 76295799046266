import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class OnlineTutoring extends Component {
    render() {
        return (
            <div className="content-wrap courses-single-page">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content-page-wrap">
                                <div className="flat-tabs bg-fff border-ra4 border-f-e6f3ff">
                                    <ul className="tab-title style1 clearfix border-bt-e6f3ff">
                                        <li className="item-title  overview">
                                            <span className="inner">OVERVIEW</span>
                                        </li>
                                        <li className="item-title event">
                                            <span className="inner">DESCRIPTION</span>
                                        </li>
                                        <li className="item-title requirements">
                                            <span className="inner">DOWNLOAD SAMPLES</span>
                                        </li>
                                    </ul>
                                    <div className="tab-content-wrap">
                                        <div className="tab-content">
                                            <div className="item-content">
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="text-wrap img-right clearfix">
                                                            <h6 className="title">Key Issue</h6>
                                                            <p className="text">Competent online tutorial services are hard to come by since they need a lot of technology and resources. This is because, in addition to highly skilled teachers, multiple internet communication means are required. Video conferencing, voice calls, online discussion forums, and other methods may be used. As a result, agencies must rely on modern software tools and teachers.</p>
                                                            <p className="text">With increasing competition, educational customers are looking for more assistance to augment their learning methods. They rely on online tutors who can assist their students from all around the world 24 hours a day, seven days a week. Nevertheless, just a few service providers provide this option. Clients also prefer teachers who explain ideas in both their local language and the institute's official language. As a result, clients must check that the online tutoring business with which they work satisfies all of these requirements.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="img-single-small flat-text-center">
                                                            <img src="/images/courses/model-img.webp" alt="nstructive" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 flex-align-center">
                                                        <div className="img-single-small flat-text-center">
                                                            <img src="images/courses/tutoring.jpg" alt="nstructive" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="text-wrap img-left clearfix">
                                                            <h6 className="title">Types of Online Tutoring Services</h6>
                                                            <p className="text">Our tutors have extensive expertise teaching a variety of disciplines and courses. We have a large network of teachers all around the world. As a result, in collaboration with our content and technological teams, they offer a variety of tutorial services to fulfil the needs of clients globally.</p>
                                                            <ul className="list-skill">
                                                                <li> Exam Tutorials</li>
                                                                <li> Doubt-clearing Sessions</li>
                                                                <li> Subject Specific Tutorials</li>
                                                                <li> Group Tutorials</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="text-wrap">
                                                            <h6 className="title">Overcoming the Challenges of Providing Effective and Personalized Online Tutoring Services</h6>
                                                            <p className="text">One of the main challenges is ensuring that the tutoring services are effective and engaging. Online tutoring requires a different approach than traditional in-person tutoring, and it can be challenging to ensure that the content is presented in a way that is both effective and engaging. Moreover, providing online tutoring services that meet the needs and preferences of each individual customer can be difficult, especially when dealing with a diverse customer base.</p>
                                                            <p className="text">Another challenge is ensuring that the online tutoring services are personalized to meet the unique needs of each customer. Providing a one-size-fits-all tutoring solution may not be effective, as each customer has different learning needs, goals, and preferences. Personalizing the online tutoring services requires a deep understanding of the customer's needs and preferences, as well as the ability to tailor the content to meet those needs.</p>
                                                            <p className="text">Moreover, providing effective online tutoring services requires specialized resources and expertise. This includes knowledge of teaching methods, subject matter expertise, and the ability to use technology effectively to deliver the tutoring services. It can be challenging for services companies to find qualified tutors with the necessary skills and expertise to provide effective online tutoring services.</p>
                                                            <p className="text">In conclusion, providing effective and personalized online tutoring services can be challenging for services companies due to various factors. By addressing the challenges of effectiveness and engagement, personalization, expertise and training, cost-effectiveness, and accessibility, services companies can improve their online tutoring services and enhance their professional image and communication with customers.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="wrap-checkmark-tag clearfix">
                                                            <div className="tags one-of-two">
                                                                <Link to="#" className="tag-item bg-7ffe53">Online Tutoring</Link>
                                                                <Link to="#" className="tag-item bg-fe5367">Doubt Sessions</Link>
                                                                <Link to="#" className="tag-item bg-536dfe">All Streams</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-content">
                                            <div className="item-content">
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <div className="text-wrap">
                                                            <h6 className="title">How Nstructive Can Assit?</h6>
                                                            <p className="text">Nstructive offers personalized online tutoring services that are tailored to the unique needs and preferences of each customer. Our tutors take the time to understand each customer's learning needs, goals, and preferences, and tailor the tutoring content accordingly. This ensures that each customer receives a personalized tutoring experience that is effective and engaging.</p>
                                                            <p className="text">Nstructive employs a team of qualified and experienced tutors who are experts in their respective fields. Our tutors have the necessary subject matter expertise, teaching methods, and technology skills to deliver effective online tutoring services. They are also trained to use the latest teaching techniques and technology tools to provide an engaging and interactive tutoring experience.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="img-single-small flat-text-center">
                                                            <img src="images/courses/tutoring-1.jpg" alt="nstructive" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="text-wrap">
                                                            <h6 className="title">High Availability and Global Reach</h6>
                                                            <p className="text">Nstructive's online tutoring services are accessible to customers worldwide. We offer tutoring services in multiple languages and time zones to ensure that our services are accessible to customers from diverse backgrounds and locations.</p>
                                                            <p className="text">Nstructive's online tutoring services are designed to be effective and engaging. We use the latest technology tools and teaching methods to deliver an interactive and engaging tutoring experience. Our tutoring content is presented in a way that is easy to understand, and our tutors provide feedback and support to help customers achieve their learning goals.</p>
                                                            <p className="text">In conclusion, Nstructive's online tutoring services are superior to those provided by other services companies due to our personalized approach, qualified and experienced tutors, cost-effective pricing, global accessibility, and effective and engaging tutoring content. By choosing Nstructive for their online tutoring needs, customers can receive a high-quality tutoring experience that is tailored to their unique needs and preferences.</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="wrap-checkmark-tag clearfix">
                                                            <div className="tags one-of-two">
                                                                <Link to="#" className="tag-item bg-7ffe53">Online Tutoring</Link>
                                                                <Link to="#" className="tag-item bg-fe5367">Doubt Sessions</Link>
                                                                <Link to="#" className="tag-item bg-536dfe">All Streams</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-content">
                                            <div className="item-content">
                                                <div className="row">
                                                    <div className="col-md-12"></div>
                                                </div>
                                            </div>
                                            {/* <div className="wrap-checkmark-tag clearfix">
                                                <div className="tags one-of-two">
                                                    <Link to="#" className="tag-item bg-7ffe53">Textbook Solution</Link>
                                                    <Link to="#" className="tag-item bg-fe5367">K12</Link>
                                                    <Link to="#" className="tag-item bg-536dfe">All Streams</Link>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
}

export default OnlineTutoring;