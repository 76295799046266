import React, { Component } from 'react';
import { Link } from 'react-router-dom'
class FeatureComponent extends Component {
    render() {
        return (
            <section className="flat-featured style2">
                <div className="container-fluid">
                    <div className="sub-column clearfix">
                        <div className="one-of-two">
                            <div className="textbox">
                                <h3 className="title">Study any topic, anytime. Explore thousands of courses $19.99</h3>
                                <p className="text">Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been
                                    the industry’s standard dummy text ever since the 150 took a galley of type and scrambled it to
                                    make a type specimen book.</p>
                                <h6 className="title">Here some of the techniques you will Learn:</h6>
                                <p className="text">Lorem Ipsn gravida nibh vel velit auctor aliquet. Aenean sollicitudin elorem
                                    quis bibendum auci elit conseo.</p>
                                <ul className="list-skill">
                                    <li> Hunting down seams with the Offset filter</li>
                                    <li> Distorting an image with the Glass filter </li>
                                    <li> Using blur and noise to focus attention, simulate motion, and more </li>
                                </ul>
                                <Link to="#" className="btn-learning">BROWSE ALL LEARNING PATHS</Link>
                            </div>
                        </div>
                        <div className="one-of-two">
                            <div className="wrap-course">
                                <div className="bg-img">
                                    <div className="wrap-text">
                                        <p className="over font-Freestyle">Over </p>
                                        <p className="wrap-over"><span className="count  font-Freestyle">20,640</span> <span
                                                className="free"> Free Tutorial</span></p>
                                        <span className="end">Ends in 05h 23m 49s</span>
                                        <ul className="wrap-time-view list">
                                            <li className="time">6h 4m</li>
                                            <li className="view">3,387,872 Views</li>
                                        </ul>
                                        <h6 className="title"><Link to="#">Node.js Developer Course</Link></h6>
                                        <div className="price-wrap">
                                            <span className="sale-price text-white">$19.99 </span>
                                            <span className="price">$199.99</span>
                                        </div>
                                        <div className="wrap-btn clearfix">
                                            <Link to="#" className="flat-button btn-buy float-left v2">BUY NOW</Link>
                                            <Link to="#" className="btn-cart v2">Add To Cart</Link>
                                        </div>
                                        <div className="level">
                                            FEATURED
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default FeatureComponent;