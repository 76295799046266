import React, { Component } from 'react'
import {Link, withRouter} from 'react-router-dom'
import dataintructors from '../home01/dataIntructor'
class Instructor extends Component {
    render() {
        return (
            <div className="flat-team row">
                {
                            dataintructors.map(data => (
                                <div className="col-lg-4 col-md-12 effect-animation" data-animation={data.dataanimate} data-animation-delay={data.delay} data-animation-offset={data.offset} key={data.id}>
                                    <div className={data.classnames}>
                                        <div className="avata-member">
                                            <Link to="#"><img src={data.src} alt="nstructive"
                                                    className="transition-img" /></Link>
                                        </div>
                                        <div className="info-member">
                                            <h6 className="name"><Link to="#">{data.name}</Link></h6>
                                            <p className="position">{data.position}</p>
                                            <p className="description">
                                                {data.description}
                                            </p>
                                        </div>
                                        <div className={data.classicon}>
                                            <ul className="list">
                                                <li className="facbook"><Link to="#"><i className="fa fa-facebook" /></Link></li>
                                                <li className="instagram"><Link to="#"><i className="fab fa-instagram" /></Link></li>
                                                <li className="skype"><Link to="#"><i className="fab fa-skype" /></Link></li>
                                                <li className="github"><Link to="#"><i className="fab fa-github" /></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
            </div>
        );
    }
}

export default withRouter(Instructor);