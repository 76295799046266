import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class UsefulLink extends Component {
    render() {
        return (
            <div className="widget-link widget-ft">
                <h6 className=" widget-title">USEFUL LINK</h6>
                <div className="list-wrap clearfix">
                    <ul className="one-of-one">
                        <li><Link to="/about-us" onClick={() => { window.location.href = "/about-us" }}>About Us</Link></li>
                        <li><Link to="/contact-us" onClick={() => { window.location.href = "/contact-us" }}>Contact Us</Link></li>
                        <li><Link to="/faq" onClick={() => { window.location.href = "/faq" }}>FAQ</Link></li>
                        <li><Link to="/privacy-policy" onClick={() => { window.location.href = "/privacy-policy" }}>Privacy Policy</Link></li>
                        <li><Link to="/terms-and-conditions" onClick={() => { window.location.href = "/terms-and-conditions" }}>Terms and Conditions</Link></li>
                        <li><Link to="/sitemap" onClick={() => { window.location.href = "/sitemap" }}>Sitemap</Link></li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default UsefulLink;