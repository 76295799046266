import React, { Component } from 'react';
import { BottomBar, Footer, Header, TopBar, SlideTestimonial, Loader } from '../layouts/general';
import { Featured, Slider, FormApply,TabCourses } from '../layouts/home01';
import { CourseBox, TitleHeading, Instructor, EventBoxs } from '../layouts/homeanimate';

class HomeAnimate extends Component {
    constructor(props) {
        super(props);
        this.state = {
           headers: [
               {
                   id: 1,
                   names: 'Home'
               }
           ],
           titleCourses: [
               {
                   id: 1,
                   title: 'Discover Our Popular Courses',
                   classTitle: 'title',
                    classDescription: 'description',
                   description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
               }
           ],
           titleIntructor: [
                {
                    id: 1,
                    classTitle: 'title',
                    classDescription: 'description',
                    title: 'Our Skilled Instructors',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                }
            ],
            titleEvents: [
                {
                    id: 1,
                    classTitle: 'title',
                    classDescription: 'description',
                    title: 'Upcoming Events',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s.'
                }
            ],
            titleTestimonial: [
                {
                    id: 1,
                    classTitle: 'title',
                    classDescription: 'description',
                    title: 'What Our Teacher’s Say',
                    description: 'Smply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'
                }
            ],
        }
    }
    
    render() {
        return (
            <div className="bg-body3">
                <div className="boxed">
                <Loader />
                    <TopBar />
                    {
                        this.state.headers.map(data => (
                            <Header data={data} key={data.id}/>
                        ))
                    }
                    <Slider />
                    <Featured />
                    <section className="flat-popular-courses style2 v2">
                        <div className="container">
                            {
                                this.state.titleCourses.map(data =>(
                                    <TitleHeading data={data} key={data.id}/>
                                ))
                            }
                            <CourseBox />
                        </div>
                    </section>
                    <section className="flat-register-now">
                        <div className="overlay" />
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-5 effect-animation" data-animation="fadeInLeft" data-animation-delay="0.2s" data-animation-offset="75%">
                                    <FormApply />
                                </div>
                                <div className="col-lg-7 effect-animation" data-animation="fadeInRight" data-animation-delay="0.2" data-animation-offset="75%">
                                    <TabCourses />
                                </div>
                            </div>
                        </div>
                    </section>    
                    <section className="flat-skilled-instructors">
                        <div className="container">
                            {
                                this.state.titleIntructor.map(data =>(
                                    <TitleHeading data={data} key={data.id}/>
                                ))
                            }
                            <Instructor />
                        </div>
                    </section>
                    <section className="flat-upcoming-events style1">
                        <div className="container">
                            {
                                this.state.titleEvents.map(data =>(
                                    <TitleHeading data={data} key={data.id}/>
                                ))
                            }
                            <div className="flat-events style2">
                                <EventBoxs />
                            </div>
                        </div>
                    </section>
                    <section className="flat-testimonials style1">
                        <div className="container">
                            {
                                this.state.titleTestimonial.map(data =>(
                                    <TitleHeading data={data} key={data.id}/>
                                ))
                            }
                            <SlideTestimonial />
                        </div>
                    </section>
                    <section className="flat-feature parallax parallax2">
                        <div className="container">
                            <div className="row flat-iconbox style1">
                                <div className="col-lg-4 col-md-4">
                                    <div className="iconbox style1 left effect-animation" data-animation="zoomIn" data-animation-delay={0} data-animation-offset="75%">
                                        <div className="iconbox-icon">
                                            <span className="icon-book" />
                                        </div>
                                        <div className="iconbox-content">
                                            <h5 className="title">80,000 ONLINE COURSES</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="iconbox style1 center effect-animation" data-animation="zoomIn" data-animation-delay="0.2s" data-animation-offset="75%">
                                        <div className="iconbox-icon">
                                            <span className="icon-people" />
                                        </div>
                                        <div className="iconbox-content">
                                            <h5 className="title">EXPERT INSTRUCTION</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <div className="iconbox style1 right effect-animation" data-animation="zoomIn" data-animation-delay="0.4s" data-animation-offset="75%">
                                        <div className="iconbox-icon">
                                            <span className="icon-key" />
                                        </div>
                                        <div className="iconbox-content">
                                            <h5 className="title">LIFETIME ACCESS</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                    <BottomBar />
                </div>
            </div>
        );
    }
}

export default HomeAnimate;